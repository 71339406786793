import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner } from '@ionic/react';
import { arrowBack, keyOutline, lockClosedOutline, mailOutline } from "ionicons/icons";
import { default as React, useEffect, useState } from 'react';
import { Link, useHistory,  withRouter } from "react-router-dom";
import { resetPassword } from '../../services/api';
import SmallHeader from '../SmallHeader';
import i18next from 'i18next';

const ResetPasswordComponent = (props) => {
  const queryData = new URLSearchParams(props.location.search);

  const history = useHistory();
  const [email, setEmail] = useState(queryData.get('email'));
  const [key, setKey] = useState('');
  const [password, setPassword] = useState('');
  const [iserror, setIserror] = useState(false);
  const [issuccess, setIsSuccess] = useState(false);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = () => {
    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      return;
    }

    if (!key) {
      setMessage("Please enter a valid key");
      setIserror(true);
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Please enter your password");
      setIserror(true);
      return;
    }

    const resetPasswordData = {
      "email": email,
      "key": key,
      "password": password
    }

    setIsLoading(true);

    resetPassword(resetPasswordData)
      .then((response) => {
        console.log(response.data);
        setIserror(false);
        setMessage(response.data.message);
        setIsSuccess(true);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err.response)
        console.log("error catch search:", err.message);
        setMessage(err.response.data.message);
        setIserror(true);
        setIsLoading(false);
      });
  };

  useEffect(()=>{
    let queryData = new URLSearchParams(props.location.search);
    setEmail(queryData.get('email'));
  },[props.location])

  return (
    <IonPage>
      <IonContent fullscreen>
        <SmallHeader />
        <div className="app-page-wrapper app-page-wrapper-login-routes">
          <div className="app-page-content">
            <div className="app-headline">
              <h1>{i18next.t('Pages.NewPassword.Header.Title')}</h1>
              <p>{i18next.t('Pages.NewPassword.Header.Subtitle')}</p>
            </div>
            <div className="form-wrapper form-wrapper-input-white">
              <IonRow>
                <IonCol>
                  <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    cssClass="my-custom-class"
                    header={"Error!"}
                    message={message}
                    buttons={["Dismiss"]}
                  />
                  <IonAlert
                    isOpen={issuccess}
                    onDidDismiss={() => {
                      setIsSuccess(false);
                      history.push('/login/?reset=true');
                    }}
                    cssClass="my-custom-class"
                    header={"Success"}
                    message={message}
                    buttons={["Dismiss"]}
                  />

                </IonCol>
              </IonRow>
              <div className="form-item">
                <IonRow>
                  <IonCol>
                    <div className="form-input">
                      <IonInput type="email" placeholder={i18next.t('Common.Form.Email')}
                        value={email} readonly
                        onIonChange={(e) => setEmail(e.detail.value)} />
                      <div className="form-icon">
                        <IonIcon icon={mailOutline} />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              <div className="form-item">
                <IonRow>
                  <IonCol>
                    <div className="form-input">
                      <IonInput type="text" placeholder={i18next.t('Common.Form.OTP')}
                        value={key}
                        onIonChange={(e) => setKey(e.detail.value)} />
                      <div className="form-icon">
                        <IonIcon icon={keyOutline} />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              <div className="form-item">
                <IonRow>
                  <IonCol>
                    <div className="form-input">
                      <IonInput type="password" placeholder={i18next.t('Common.Form.Password')}
                        value={password}
                        onIonChange={(e) => setPassword(e.detail.value)} />
                      <div className="form-icon">
                        <IonIcon icon={lockClosedOutline} />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              <div className="form-item form-item-submit">
                <IonRow>
                  <IonCol size="12">
                    <button className='btn btn-colored btn-loading'
                      disabled={isLoading}
                      onClick={handleResetPassword}
                    >
                      {isLoading ? <IonSpinner name="dots" /> : i18next.t('Common.Form.NewPasswordSubmit')}
                    </button>
                  </IonCol>
                  <IonCol size="12">
                    <div className="app-alert">
                      <IonAlert
                        isOpen={iserror}
                        onDidDismiss={() => setIserror(false)}
                        cssClass="my-custom-class"
                        header={"Error!"}
                        message={message}
                        buttons={["Dismiss"]}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </div>

            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(ResetPasswordComponent);

