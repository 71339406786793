import {
  IonApp,
  setupIonicReact
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';

import AppRouterNavigator from './navigation/AppRouterNavigator';
import { AppContextProvider } from './store/State';

/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';

import { i18n } from "./translations/i18n";


import { useEffect } from 'react';

import { NativeModules, Platform } from 'react-native';


setupIonicReact();

const App = () => {
  useEffect(() => {
    const locale =
      Platform.OS === 'ios'
        ? NativeModules.SettingsManager.settings.AppleLocale
        : NativeModules.I18nManager ? NativeModules.I18nManager.localeIdentifier : "";
    console.log(locale.substring(0, 2));
    let locale_new = locale.substring(0, 2);
    locale_new = locale_new ? locale_new : 'en';
    console.log("locale_new", locale_new)
    i18n.changeLanguage(locale);
  }, [])
  return (
    <IonApp>
      <AppContextProvider>
        <AppRouterNavigator></AppRouterNavigator>
      </AppContextProvider>
    </IonApp>
  )
};

export default App;
