import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonItem, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { getBlogList } from '../../services/api';
import HeaderComponent from '../HeaderComponent';
import './index.scss';
import HeaderBackComponent from '../HeaderBackComponent';

const BlogComponent = () => {

  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    getBlogList()
      .then((response) => setBlogData(response.data.blogs))
      .catch((error) => alert(error))
      .finally();
  }, []);

  const getPostContent = (postdata) => {
    console.log(postdata);
    return postdata.substring(
      postdata.lastIndexOf("<!-- wp:paragraph -->") + 1,
      postdata.lastIndexOf("<!-- /wp:paragraph -->")
    );
  }

  return (
    <IonPage>
      <HeaderBackComponent
        title="Stories"
        backButton="/home"
      />
      <IonContent>
        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="app-blog-page">
              <div className="app-blog-title">
                <h4>Ultime novità</h4>
              </div>
              {blogData.map(blog => {
              return (
                <div className="app-blog-item">
                  <div className="app-blog-item-wrapper">
                    <div className="app-blog-image">
                      <img class="blog-img-wrapper" src={blog.thumbnail}/>
                    </div>
                    <div className="app-blog-content">
                      <div className="app-blog-content-wrapper">
                        <div 
                        className="app-blog-content-title">
                          <h3>{blog.post_title}</h3>
                        </div>
                        <div className="app-blog-content-excerpt">
                        <div dangerouslySetInnerHTML={{ __html: blog.post_excerpt }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BlogComponent;
