import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
import { arrowBack, locationOutline, lockClosedOutline, mailOutline, personOutline } from "ionicons/icons";
import React, { useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { userRegistration } from '../../services/api';
import { countriesData } from "../../services/country";
import { AppContext } from '../../store/State';
import SmallHeader from '../SmallHeader';
import FacebookAuthService from '../../services/facebook-auth-service';
import i18next from 'i18next';

const RegistrationComponent = () => {
  const coutryArray = countriesData();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [country, setCountry] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [lang, setLang] = useState(i18next.language);
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AppContext);
  const handleRegistration = () => {

    if (!firstname) {
      setMessage("Please enter a valid first name");
      setIserror(true);
      return;
    }

    if (!lastname) {
      setMessage("Please enter a valid last name");
      setIserror(true);
      return;
    }

    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Please enter your password");
      setIserror(true);
      return;
    }

    if (!newsletter) {
      setMessage("Please select news letter");
      setIserror(true);
      return;
    }

    if (!policy) {
      setMessage("Please select policy");
      setIserror(true);
      return;
    }

    if (!lang) {
      setMessage("Please select language");
      setIserror(true);
      return;
    }

    const registrationData = {
      "email": email,
      "first_name": firstname,
      "last_name": lastname,
      "password": password,
      "newsletter": newsletter,
      "policy": policy,
      "country": country,
      "lang": lang
    }

    setIsLoading(true);

    userRegistration(registrationData)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('userData', JSON.stringify(response.data.data));
          localStorage.setItem('token', response.data.data.token);
          dispatch({ type: "setIsAuthenticated", isAuthenticated: true });
          dispatch({ type: "setUserDetails", userData: response.data.data });
          history.push('/home');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setMessage(err.response.data.data.message);
        setIserror(true);
        setIsLoading(false);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error!"}
          message={message}
          buttons={["Dismiss"]}
        />
        <div className="app-page">
          <SmallHeader />
          <div className="app-page-wrapper app-page-wrapper-login-routes">
            <div className="app-page-content">
              <div className="app-headline">
                <h1>{i18next.t('Pages.Registration.Header.Title')}</h1>
                <p>{i18next.t('Pages.Registration.Header.Subtitle')}</p>
              </div>
              <div className="form-wrapper form-wrapper-input-white">
                <div className="form-item">
                  <IonRow>
                    <IonCol size="6">
                      <div className="form-input">
                        <IonInput type="text" value={firstname} placeholder={i18next.t('Common.Form.Name')}
                          onIonChange={(e) => setFirstname(e.detail.value)} />
                        <div className="form-icon">
                          <IonIcon icon={personOutline} />
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="6">
                      <div className="form-input">
                        <IonInput type="text" value={lastname} placeholder={i18next.t('Common.Form.Surname')}
                          onIonChange={(e) => setLastname(e.detail.value)} />
                        <div className="form-icon">
                          <IonIcon icon={personOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input">
                        <IonInput type="email" placeholder={i18next.t('Common.Form.Email')}
                          value={email}
                          onIonChange={(e) => setEmail(e.detail.value)} />
                        <div className="form-icon">
                          <IonIcon icon={mailOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input">
                        <IonInput type="password" placeholder={i18next.t('Common.Form.Password')}
                          value={password}
                          onIonChange={(e) => setPassword(e.detail.value)} />
                        <div className="form-icon">
                          <IonIcon icon={lockClosedOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input form-checkbox">

                        <IonCheckbox color="primary" slot="start" checked={policy} onIonChange={(e) => {
                          setNewsletter(e.detail.checked);
                          setPolicy(e.detail.checked);
                        }} />
                        <IonLabel>{i18next.t('Common.Form.PolicyControl')}</IonLabel>

                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item form-item-submit">
                  <IonRow>
                    <IonCol>
                      <button className='btn btn-colored btn-loading' disabled={isLoading} shape="round" onClick={handleRegistration}>
                        {isLoading ? <IonSpinner name="dots" /> : i18next.t('Common.Form.Register')}
                      </button>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
              <div className="form-social-login">
                <div className="form-social-login-title">
                  <p>{i18next.t('Common.Form.SocialLogin.Title')}</p>
                </div>
                <div className="form-social-login-item">
                  <FacebookAuthService></FacebookAuthService>
                </div>
                <div className="form-social-login-item">
                  {/* <GoogleAuthService></GoogleAuthService> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>

      {/* <IonContent fullscreen className="ion-padding ion-text-center">
        <IonItem>
          <Link to="/login">
            <IonIcon size='large' color='dark' icon={arrowBack} />
          </Link>
          <IonLabel>Sign up for free</IonLabel>
        </IonItem>
        <IonGrid>
          <IonItem lines='none'>
            <IonLabel>Already have an account? <Link to="/login">Sign In</Link></IonLabel>
          </IonItem>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='6'>
              <IonItem>
                <IonInput type="text" value={firstname} placeholder="First name"
                  onIonChange={(e) => setFirstname(e.detail.value)} />
                <IonIcon icon={personOutline} />
              </IonItem>
            </IonCol>
            <IonCol size='6'>
              <IonItem>
                <IonInput type="text" value={lastname} placeholder="Last name"
                  onIonChange={(e) => setLastname(e.detail.value)} />
                <IonIcon icon={personOutline} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput type="email" placeholder='Email'
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)} />
                <IonIcon icon={mailOutline} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput type="password" placeholder='Password'
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value)} />
                <IonIcon icon={lockClosedOutline} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel color="medium">Select Country</IonLabel>
                <IonSelect value={country}
                  onIonChange={(e) => setCountry(e.detail.value)}>
                  {coutryArray && coutryArray.length > 0 && coutryArray.map((countryData, index) => {
                    return (
                      <IonSelectOption key={index} value={countryData.code}>{countryData.name}</IonSelectOption>
                    )
                  })}
                </IonSelect>
                <IonIcon icon={locationOutline} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel color="medium">Language</IonLabel>
                <IonSelect value={lang}
                  onIonChange={(e) => setLang(e.detail.value)}>
                  <IonSelectOption value="en">English</IonSelectOption>
                  <IonSelectOption value="it">Italian</IonSelectOption>
                  <IonSelectOption value="de">German</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines='none'>
                <IonLabel color='medium'>I accept the terms of use and privacy</IonLabel>
                <IonCheckbox slot="start" checked={policy} onIonChange={e => { return setNewsletter(e.detail.checked), setPolicy(e.detail.checked); }} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton disabled={isLoading} shape="round" onClick={handleRegistration}>
                Sign up
                {isLoading ? <IonSpinner name="dots" /> : ''}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent> */}
    </IonPage>
  );
};

export default RegistrationComponent;

