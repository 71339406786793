import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText
} from "@ionic/react";
import { useEffect, useState, useContext } from "react";
import ReactPaginate from "react-paginate";
import { listTableReservation } from "../../services/api";
import HeaderBackComponent from "../HeaderBackComponent";
import moment from "moment";
import "moment/locale/it";

import { checkHasCapability } from "../../services/user-access";
import { AppContext } from "../../store/State";
import i18next, { use } from "i18next";

const TableReservationList = () => {
  const [reservationData, setReservationData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filter, setFilter] = useState('future');
  const [reservationStatus, setStatus] = useState('all');
  const [total, setPageTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const { state } = useContext(AppContext);

  useEffect(() => {
    setIsLoading(true)
    listTableReservation(page, size, filter, reservationStatus)
      .then((response) => {
        setReservationData(response.data.data.reservations);
        setPageTotal(response.data.data.total_page);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error)
      })
      .finally();
  }, [page, filter, reservationStatus, refreshList]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const doRefresh = () => {
    console.log("doRefresh")
    setRefreshList(!refreshList);
  }
  return (
    <IonPage>
      <HeaderBackComponent
        title={checkHasCapability(state.userData, "confirm_reservation") ? i18next.t('Pages.TableReservationList.Header.Admin.Title') : i18next.t('Pages.TableReservationList.Header.User.Title')}
        backButton="/home"
        type="colored"
      />
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonContent fullscreen>
        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="app-reservation-filter">
              <div className="label">
                {i18next.t('Pages.TableReservationList.Content.Status.FilterLabel')}
              </div>
              <div className="app-reservation-filter-buttons">
                <div className={(reservationStatus == 'all') ? "app-reservation-filter-item active" : "app-reservation-filter-item"} onClick={() => { setStatus('all') }}>
                  {i18next.t('Pages.TableReservationList.Content.Status.All')}
                </div>
                <div className={(reservationStatus == 'requested') ? "app-reservation-filter-item active" : "app-reservation-filter-item"} onClick={() => { setStatus('requested') }}>
                  {i18next.t('Pages.TableReservationList.Content.Status.Requested')}
                </div>
                <div className={(reservationStatus == 'accepted') ? "app-reservation-filter-item active" : "app-reservation-filter-item"} onClick={() => { setStatus('accepted') }}>
                  {i18next.t('Pages.TableReservationList.Content.Status.Accepted')}
                </div>
                <div className={(reservationStatus == 'rejected') ? "app-reservation-filter-item active" : "app-reservation-filter-item"} onClick={() => { setStatus('rejected') }}>
                  {i18next.t('Pages.TableReservationList.Content.Status.Rejected')}
                </div>
              </div>
            </div>
            {isLoading && <>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
              <div className="reservation-card">
                <div className="reservation-card-wrapper">
                  <IonRow>
                    <IonCol size="8">
                      <div className="reservation-card-title">
                        <IonSkeletonText animated style={{ width: '90%', borderRadius: '6px', height: '20px' }} />
                        <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px'}} />
                      </div>
                    </IonCol>
                    <IonCol item-end size="4">
                      <div className="align-right">
                        <IonSkeletonText animated style={{ width: '70%', borderRadius: '6px', height: '15px' }} />
                      </div>
                    </IonCol>
                  </IonRow>
                  
                </div>
              </div>
            </>}
            {!isLoading && reservationData &&
              reservationData.length > 0 &&
              reservationData.map((reservationDataSingle, index) => {
                return (
                  <div className="reservation-card" key={index}>
                    <IonRouterLink
                      color="none"
                      href={"/tablereservation-list/" + reservationDataSingle.id}
                    >
                      <div className="reservation-card-wrapper">
                        <IonRow>
                          <IonCol size="8">
                            <div className="reservation-card-title">
                              {reservationDataSingle.name}{" "}
                              {reservationDataSingle.surname}{" "}
                            </div>
                            <div className="reservation-card-people">
                              Tavolo per {reservationDataSingle.number_of_adults} {reservationDataSingle.number_of_adults > 1 ? i18next.t('adulti') : 'adulto'} e {" "}
                              {reservationDataSingle.number_of_child} {reservationDataSingle.number_of_child > 1 ? i18next.t('bambini') : 'bambino'}{" "}
                            </div>
                            <div className="reservation-card-date">
                              {moment(reservationDataSingle.date).format("DD MMM YYYY")} - {" "}
                              {reservationDataSingle.time}
                            </div>
                          </IonCol>
                          <IonCol item-end size="4">
                            <div className="align-right">
                              <div className={`app-badge app-badge-${reservationDataSingle.status}`}>
                                {reservationDataSingle.status === 'rejected' && (
                                  i18next.t('Pages.TableReservationList.Content.Status.Rejected')
                                )}
                                {reservationDataSingle.status === 'accepted' && (
                                  i18next.t('Pages.TableReservationList.Content.Status.Accepted')
                                )}
                                {reservationDataSingle.status === 'requested' && (
                                  i18next.t('Pages.TableReservationList.Content.Status.Requested')
                                )}
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </div>
                    </IonRouterLink>
                  </div>
                );
              })}
            {!isLoading && reservationData.length == 0 && !isLoading &&
              <>
                No reservation
              </>
            }
          </div>
        </div>
      </IonContent>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={size}
        pageCount={total}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonRow>
            <IonCol size="6">
              <button
                className={(filter == 'future') ? "btn btn-colored" : "btn btn-outline"}
                shape="round"
                onClick={() => { setFilter('future') }}
              >
                Future
              </button>
            </IonCol>
            <IonCol size="6">
              <button
                className={(filter == 'past') ? "btn btn-colored" : "btn btn-outline"}
                shape="round"
                onClick={() => { setFilter('past') }}
              >
                Passate
              </button>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
};

export default TableReservationList;
