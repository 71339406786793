import React from 'react';
import RoomDetailsComponent from '../components/RoomDetailsComponent';

const RoomDetails = () => {

  return (
    <RoomDetailsComponent></RoomDetailsComponent>
  );
};

export default RoomDetails;