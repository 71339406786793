import {
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import React, { useContext } from "react";
import { AppContext } from "../../store/State";
import SvgItem from "../SvgItem";
import i18next from "i18next";

const HeaderComponent = () => {
  const { state } = useContext(AppContext);
  const nicename =
    state.userData && state.isAuthenticated
      ? i18next.t('Header.Hello') + ", " + state.userData.firstName + " " + state.userData.lastName
      : "Ciao, accedi al tuo account";
  // StatusBar.setStyle({ style: Style.Light });

  return (
    <><IonHeader translucent={false} className="app-header ion-no-border">
      <div className="app-header-wrapper">
        <IonToolbar className="no-bg">
          <IonTitle>{nicename}</IonTitle>
          <IonMenuButton slot="end" color="dark" menu="notification">
            <div className="notify-btn">
              <SvgItem type={'notify'} />
            </div>
          </IonMenuButton>
        </IonToolbar>
      </div>
    </IonHeader></>
  );
};

export default HeaderComponent;
