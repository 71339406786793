import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonMenuToggle,
} from "@ionic/react";
import SvgItem from "../components/SvgItem";
import {
  bedOutline,
  cameraOutline,
  home,
  listCircleOutline,
  personOutline,
} from "ionicons/icons";
import { matchPath, Redirect, Route, useLocation } from "react-router-dom";
import RegistrationComponent from "../components/RegistrationComponent";
import SideMenuComponent from "../components/SideMenuComponent";
import Blogs from "../pages/Blogs";
import Camera from "../pages/Camera";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import Login from "../pages/Login";
import LoginRequire from "../pages/LoginRequire";
import Notification from "../pages/Notification";
import ResetPassword from "../pages/ResetPassword";
import Room from "../pages/Room";
import RoomDetails from "../pages/RoomDetails";
import TableReservation from "../pages/TableReservation";
import TableReservationList from "../pages/TableReservationList";
import TableReservationDetail from "../pages/TableReservationDetail";
import Weather from "../pages/Weather";
import React, { useContext } from "react";
import { AppContext } from "../store/State";
import { checkHasCapability } from "./../services/user-access";
import NotificationMenu from "../components/NotificationMenu";
import ResturantMenu from "../pages/RestaurantMenu";
import PushNotifications from "../components/PushNotifications";
import ResturantMenuCategory from "../pages/RestaurantMenuCategory";
import Faq from "../pages/Faq"
import i18next from "i18next";
const AppRoutes = () => {
  const { state } = useContext(AppContext);

  const location = useLocation();
  const authRouteList = [
    "/login",
    "/registration",
    "/resetpassword",
    "/forgotpassword",
    "/tablereservation",
    "/live-webcam",
    "/tablereservation-list",
    "/tablereservation-list/:id",
    "/weather",
    "/restaurant-menu",
    "/rooms/:id",
  ];
  const routerMatch = matchPath(location.pathname, authRouteList);
  const hideTabBar = routerMatch ? routerMatch.path : "";

  return (
    <>
      <PushNotifications />
      <NotificationMenu></NotificationMenu>
      <SideMenuComponent></SideMenuComponent>
      <IonTabs>
        <IonRouterOutlet id="main-content">
          <Route exact path="/stories">
            <Blogs />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/restaurant-menu/category">
            <ResturantMenuCategory />
          </Route>
          <Route exact path="/restaurant-menu">
            <ResturantMenu />
          </Route>
          <Route exact path="/rooms/:id">
            <RoomDetails />
          </Route>
          <Route exact path="/rooms">
            <Room />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <ProtectedRoute
            exact
            path="/camera"
            stateData={state}
            cap={"camera"}
            component={Camera}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/weather"
            stateData={state}
            cap={"camera"}
            component={Weather}
          ></ProtectedRoute>
          <ProtectedRoute
            path="/tablereservation"
            stateData={state}
            cap={"camera"}
            component={TableReservation}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/tablereservation-list/:id"
            stateData={state}
            cap={"camera"}
            component={TableReservationDetail}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/tablereservation-list"
            stateData={state}
            cap={"camera"}
            component={TableReservationList}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/live-webcam"
            stateData={state}
            cap={"camera"}
            component={Camera}
          >
            {" "}
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/weather"
            stateData={state}
            cap={"camera"}
            component={Weather}
          >
            {" "}
          </ProtectedRoute>

          <Route exact path="/notification">
            <Notification />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/registration">
            <RegistrationComponent />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPassword />
          </Route>
        </IonRouterOutlet>
        {authRouteList.includes(hideTabBar) ? (
          <IonTabBar></IonTabBar>
        ) : (
          <IonTabBar className="app-navigation" slot="bottom">
            <IonTabButton tab="home" href="/home">
              <div className="app-navigation-icon">
                <SvgItem type={"home"} />
              </div>
              <IonLabel>{i18next.t('Components.BottomNav.Home')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="table-booking" href="/tablereservation">
              <div className="app-navigation-icon">
                <SvgItem type={"table-booking"} />
              </div>
              <IonLabel>{i18next.t('Components.BottomNav.TableReservation')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="rooms" href="/rooms">
              <div className="app-navigation-icon">
                <SvgItem type={"rooms"} />
              </div>
              <IonLabel>{i18next.t('Components.BottomNav.Room')}</IonLabel>
            </IonTabButton>
            {/* {checkHasCapability(state.userData, "navigation-webcam") && (
              <IonTabButton tab="live-webcamp" href="/live-webcam">
                <div className="app-navigation-icon">
                  <SvgItem type={"webcam"} />
                </div>
                <IonLabel>Webcam</IonLabel>
              </IonTabButton>
            )} */}
            <IonTabButton tab="live-webcamp" href="/live-webcam">
                <div className="app-navigation-icon">
                  <SvgItem type={"webcam"} />
                </div>
                <IonLabel>{i18next.t('Components.BottomNav.Webcam')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="userprofile">
              <IonMenuToggle menu="sidebar">
                <div className="app-navigation-icon">
                  <SvgItem type={"menu-nav"} />
                </div>
                <IonLabel>{i18next.t('Components.BottomNav.Menu')}</IonLabel>
              </IonMenuToggle>
            </IonTabButton>
          </IonTabBar>
        )}
      </IonTabs>
    </>
  );
};

const ProtectedRoute = (props) => {
  return (
    <Route
      exact
      path={props.path}
      component={
        checkHasCapability(props.stateData.userData, props.cap)
          ? props.component
          : LoginRequire
      }
    ></Route>
  );
};

export default AppRoutes;
