import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  IonModal,
  IonButtons,
  IonHeader,
  IonSpinner,
  IonLoading,
  IonDatetime,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonSkeletonText
} from "@ionic/react";
import { useEffect, useState, useRef, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  detailTableReservation,
  saveComment,
  rejectTableReservation,
  acceptTableReservation,
  getReservationData,
} from "../../services/api";
import HeaderComponent from "../HeaderComponent";
import HeaderBackComponent from "../HeaderBackComponent";
import SvgItem from "../SvgItem";
import { AppContext } from "../../store/State";
import { checkHasCapability } from "../../services/user-access";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/it";
import { Swiper, SwiperSlide } from "swiper/react";

const TableReservationList = () => {
  const history = useHistory();
  const { id } = useParams();
  const [reservationData, setReservationData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [commentData, setCommentData] = useState("");
  const [declineComment, setDeclineComment] = useState("");
  const [declineLoading, setIsDeclineLoading] = useState(false);
  const [acceptComment, setAcceptComment] = useState("");
  const [acceptLoading, setIsAcceptLoading] = useState(false);
  const [prePaymentPrice, setPrePaymentPrice] = useState(0);
  const [reservationDataLogs, setReservationDataLogs] = useState({});
  const [fullDate, setFullDate] = useState(moment().format("YYYY-MM-DD"));
  const modal = useRef();
  const input = useRef();
  const datepicker = useRef();
  const acceptModal = useRef();
  const timepicker = useRef();
  const { state } = useContext(AppContext);
  const [isAcceptModal, setAcceptModal] = useState(0);
  const [isRejectModal, setRejectModal] = useState(0);

  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [calenderMonth, setCalenderMonth] = useState(
    moment().format("MM/YYYY")
  );
  const [selectedDate, setDate] = useState(moment().format("DD"));
  const [selectedTime, setSelectedTime] = useState("");
  const [reservationDates, setReservationDates] = useState([]);
  useEffect(() => {
    detailTableReservation(id)
      .then((response) => {
        console.log(response);
        setReservationData(response.data.data);
      })
      .catch((error) => alert(error))
      .finally();
    getReservationData()
      .then((response) => {
        setReservationDataLogs(response.data.data);
      })
      .catch((error) => alert(error))
      .finally();
  }, [id]);

  useEffect(() => {
    if (
      reservationData &&
      reservationDataLogs &&
      reservationDataLogs[fullDate] &&
      reservationDataLogs[fullDate].event
    ) {
      let adultPrice = parseInt(
        reservationDataLogs[fullDate].event.adult_price
      );
      let childPrice = parseInt(
        reservationDataLogs[fullDate].event.child_price
      );
      adultPrice = parseInt(reservationData.number_of_adults) * adultPrice;
      childPrice = parseInt(reservationData.number_of_child) * childPrice;
      setPrePaymentPrice(adultPrice + childPrice);
    }
  }, [reservationDataLogs, reservationData]);
  const createComment = () => {
    if (!commentData) {
      setMessage("Please enter a valid comment data");
      setIserror(true);
      return;
    }

    const data = {
      id: id,
      other_information: commentData,
      date: "",
      time: "",
    };

    setIsLoading(true);

    saveComment(data)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success ...!");
        }
        setIsLoading(false);
        setReservationData(response.data.data);
        setCommentData("");
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setIserror(true);
        setIsLoading(false);
      });
  };
  function dismiss() {
    setRejectModal(0);
  }
  function acceptDismiss() {
    setAcceptModal(0);
  }
  function declineReservation() {
    setIsDeclineLoading(true);
    rejectTableReservation(reservationData.id, declineComment)
      .then((response) => {
        setIsDeclineLoading(false);
        setRejectModal(false);
        history.push("/tablereservation-list");
      })
      .catch((err) => {
        setIsDeclineLoading(false);
        setRejectModal(false);
      });
  }
  function acceptReservation() {
    setIsAcceptLoading(true);
    let data = {
      id: reservationData.id,
      other_information: acceptComment,
      date: currentYear + "-" + currentMonth + "-" + selectedDate,
      time: selectedTime,
      pre_payment_amount: prePaymentPrice,
    };
    acceptTableReservation(data)
      .then((response) => {
        setIsAcceptLoading(false);
        setAcceptModal(false);
        history.push("/tablereservation-list");
      })
      .catch((err) => {
        setIsAcceptLoading(false);
        setAcceptModal(false)
      });

  }

  const currentDay = moment();
  let currentDayClone = currentDay;
  let bookableMonth = [];
  bookableMonth[currentDay.format("MM/YYYY")] =
    currentDay.format("MMMM - YYYY");
  {
    for (var i = 0; i < 2; i++) {
      currentDayClone = currentDayClone.add(1, "M");
      bookableMonth[currentDayClone.format("MM/YYYY")] =
        currentDayClone.format("MMMM - YYYY");
    }
  }

  const calenderMonthMoment = moment({
    year: currentYear,
    month: currentMonth - 1,
    day: 1,
  });

  useEffect(() => {
    setReservationDates(Object.keys(reservationDataLogs));
  }, [reservationData]);

  return (
    <IonPage>
      <HeaderBackComponent
        title={i18next.t('Pages.TableReservationDetail.Header.Title') + ' ' + reservationData.id}
        backButton="/tablereservation-list"
        type="colored"
      />
      <IonContent fullscreen>
        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="reservation-details">
              <div className="reservation-details-content">
                <div className="reservation-details-label">
                  <p>{i18next.t('Pages.TableReservationDetail.ReservationDetails.Title')}</p>
                </div>
                <div className="reservation-details-block">
                  <h3>
                    Tavolo per {reservationData.number_of_adults} persone e{" "}
                    {reservationData.number_of_child} bambino{" "}
                  </h3>
                  <p>
                    Il giorno{" "}
                    {moment(reservationData.date).format("DD MMM YYYY")} alle
                    ore {reservationData.time}
                  </p>
                </div>
                <div className="reservation-details-label">
                  <p>{i18next.t('Pages.TableReservationDetail.ReservationDetailsClient.Title')}</p>
                </div>
                <div className="reservation-details-block">
                  <h3>
                    {reservationData.name} {reservationData.surname}
                  </h3>
                  <p>
                    {reservationData.email}
                    <br />
                    {reservationData.phone}
                  </p>
                </div>
              </div>
            </div>
            <div className="reservation-message">
              <div className="reservation-message-label">
                <p>{i18next.t('Pages.TableReservationDetail.Comments.Title')}</p>
              </div>
              <div className="reservation-message-block">
                <div className="reservation-message-block-inner">
                  <div className="reservation-message-block-add-new">
                    <div className="form-wrapper">
                      <div className="form-item form-item-inline">
                        <IonRow>
                          <IonCol size="10">
                            <div className="form-input">
                              <IonTextarea
                                type="text"
                                placeholder={i18next.t('Pages.TableReservationDetail.Comments.Title')}
                                value={commentData}
                                onIonChange={(e) =>
                                  setCommentData(e.detail.value)
                                }
                              />
                            </div>
                          </IonCol>
                          <IonCol size="2">
                            <div className="form-input">
                              <button
                                className="btn btn-colored btn-icon"
                                disabled={isLoading}
                                onClick={createComment}
                              >
                                <SvgItem type={"plus"} />
                              </button>
                            </div>
                          </IonCol>
                        </IonRow>
                      </div>
                    </div>
                  </div>
                  <div className="reservation-message-block-list">
                    {reservationData.comments
                      ? reservationData.comments.map((comment, index) => {
                        if (comment.content == '') { return <></> }
                        return (
                          <div className="reservation-message-block-list-item">
                            <div className="reservation-message-block-list-item-header">
                              <div className="reservation-message-block-list-item-date">
                                {moment(comment.date).format(
                                  "DD MMM YYYY HH:MM"
                                )}
                              </div>
                              <div className="reservation-message-block-list-item-user">
                                {comment.user.first_name}
                              </div>
                            </div>
                            <div className="reservation-message-block-list-item-content">
                              <div className="reservation-message-block-list-item-content-inner">
                                {comment.content}
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>


      <IonModal className="app-modal" ref={modal} isOpen={isRejectModal}>
        <IonHeader className="app-modal-header">
          <div className="app-modal-title">
            <h2>{i18next.t('Pages.TableReservationAction.Decline.Title')}</h2>
          </div>
        </IonHeader>

        <IonContent fullscreen className="app-modal-content">
          <div className="app-modal-body">
            <div className="form-wrapper">
              <div className="form-item">
                <div className="form-item-title">
                  <h4>{i18next.t('Pages.TableReservationAction.Decline.Step1.Title')}</h4>
                  <p>{i18next.t('Pages.TableReservationAction.Decline.Step1.Subtitle')}</p>
                </div>
                <div className="form-input">
                  <IonTextarea
                    ref={input}
                    onIonChange={(e) => {
                      setDeclineComment(e.detail.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </IonContent>
        {<IonFooter className="app-modal-footer">
          <IonToolbar color="white">
            <div className="app-modal-footer-submit">
              <button className="btn btn-simple" onClick={() => dismiss()}>{i18next.t('Pages.TableReservationAction.Accept.CancelButton')}</button>
              <button className="btn btn-colored btn-colored-danger btn-loading" onClick={() => declineReservation()}>{i18next.t('Pages.TableReservationAction.Decline.RejectButton')}</button>
            </div>
          </IonToolbar>
        </IonFooter>}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={declineLoading}
          message={"Please wait..."}
          duration={5000}
        />
      </IonModal>

      <IonModal className="app-modal app-modal-reservation" ref={acceptModal} isOpen={isAcceptModal}>
        <IonHeader className="app-modal-header">
          <div className="app-modal-title">
            <h2>{i18next.t('Pages.TableReservationAction.Accept.Title')}</h2>
          </div>
        </IonHeader>
        <IonContent fullscreen className="app-modal-content">
          <div className="app-modal-body">
            <div className="form-wrapper">

              {/* Step Date */}
              <div className="form-item">
                <div className="form-item-title">
                  <h4>{i18next.t('Pages.TableReservationAction.Accept.Step1.Title')}</h4>
                  <p>{i18next.t('Pages.TableReservationAction.Accept.Step1.Subtitle')}</p>
                </div>
                <div className="form-item-card">

                  <IonRow className="form-item-card-row no-bd">
                    <IonCol size="4" size-sm>
                      <div className="form-item-card-label">
                        <label>{i18next.t('Pages.TableReservation.Form.DateSlot.Month')}</label>
                      </div>
                    </IonCol>
                    <IonCol size="8" size-sm>
                      <div className="form-item-card-label no-pr no-mb">
                        <div className="form-item-card-select">
                          <IonSelect
                            interface="popover"
                            class="ion-select-overflow"
                            placeholder="Select Month"
                            value={calenderMonth}
                            onIonChange={(e) => {
                              let allData = e.detail.value.split("/");
                              setCurrentMonth(allData[0]);
                              setCurrentYear(allData[1]);
                              setCalenderMonth(e.detail.value);
                              setSelectedTime("");
                              setDate("");
                            }}
                          >
                            {bookableMonth &&
                              Object.keys(bookableMonth).length > 0 &&
                              Object.keys(bookableMonth).map(
                                (monthData, index) => {
                                  return (
                                    <IonSelectOption
                                      key={index}
                                      value={monthData}
                                    >
                                      {bookableMonth[monthData]}
                                    </IonSelectOption>
                                  );
                                }
                              )}
                          </IonSelect>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>

                  <div className="form-item-card-slider-day">
                    <Swiper
                      spaceBetween={0}
                      initialSlide={selectedDate - 4}
                      slidesPerView={7}
                      loop={true}
                    >
                      {[...Array(31)].map((day, index) => {
                        if (calenderMonthMoment.format("MM") != currentMonth) {
                          return <></>;
                        }
                        let data = calenderMonthMoment.format("DD");
                        let isDisable = false;
                        if (
                          !reservationDates.includes(
                            currentYear + "-" + currentMonth + "-" + data
                          )
                        ) {
                          isDisable = true;
                        }
                        let weekDay = calenderMonthMoment.format("ddd");
                        calenderMonthMoment.add(1, "d");
                        return (
                          <SwiperSlide key={index}>
                            <button
                              className={
                                data === selectedDate
                                  ? "form-item-card-day form-item-card-day-selected"
                                  : "form-item-card-day"
                              }
                              disabled={
                                (data < moment().format("DD") &&
                                  calenderMonthMoment.format("MM") ==
                                  moment().format("MM")) ||
                                isDisable
                              }
                              //size="large"
                              //color={(data === selectedDate) ? "success" : ""}
                              onClick={() => {
                                setDate(data);
                              }}
                            >
                              <div className="form-item-card-day__inner">
                                <span className="weekday">{weekDay}</span>
                                <span className="data">{data}</span>
                              </div>
                            </button>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>

                </div>
              </div>

              {/* Step Time */}
              <div className="form-item">
                <div className="form-item-title">
                  <h4>{i18next.t('Pages.TableReservationAction.Accept.Step2.Title')}</h4>
                  <p>{i18next.t('Pages.TableReservationAction.Accept.Step2.Subtitle')}</p>
                </div>
                <div className="form-item-card">
                  {(reservationDataLogs[
                      currentYear + "-" + currentMonth + "-" + selectedDate
                    ] &&
                      (reservationDataLogs[
                        currentYear + "-" + currentMonth + "-" + selectedDate
                      ]["lunch"].length > 0 || reservationDataLogs[
                        currentYear + "-" + currentMonth + "-" + selectedDate
                      ]["dinner"].length > 0)) ? <>
                          {reservationDataLogs[
                            currentYear + "-" + currentMonth + "-" + selectedDate
                          ] &&
                            reservationDataLogs[
                            currentYear + "-" + currentMonth + "-" + selectedDate
                            ]["lunch"] &&
                            reservationDataLogs[
                              currentYear + "-" + currentMonth + "-" + selectedDate
                            ]["lunch"].length > 0 && 
                            <div className="form-item-card-row">
                              <IonRow className="form-item-card-row no-bd">
                                <IonCol size="12" size-sm>
                                  <div className="form-item-card-label">
                                    <label>{i18next.t('Pages.TableReservation.Form.TimeSlot.Lunch')}</label>
                                  </div>
                                </IonCol>
                              </IonRow>
                              <IonRow className="form-item-card-time-row">
                                {reservationDataLogs[
                                  currentYear + "-" + currentMonth + "-" + selectedDate
                                ]["lunch"].map((data, index) => {
                                  return (
                                    <IonCol size="3" size-sm key={index}>
                                      <button
                                        //size="large"
                                        onClick={() => {
                                          setSelectedTime(data);
                                        }}
                                        className={
                                          data === selectedTime
                                            ? "form-item-card-time active"
                                            : "form-item-card-time"
                                        }
                                      >
                                        {data}
                                      </button>
                                    </IonCol>
                                  );
                                })}
                              </IonRow>
                            </div>}
                          {reservationDataLogs[
                            currentYear + "-" + currentMonth + "-" + selectedDate
                          ] &&
                            reservationDataLogs[
                            currentYear + "-" + currentMonth + "-" + selectedDate
                            ]["dinner"] &&
                            reservationDataLogs[
                              currentYear + "-" + currentMonth + "-" + selectedDate
                            ]["dinner"].length > 0 && 
                            <div className="form-item-card-row">
                              <IonRow className="form-item-card-row no-bd">
                                <IonCol size="12" size-sm>
                                  <div className="form-item-card-label">
                                    <label>{i18next.t('Pages.TableReservation.Form.TimeSlot.Dinner')}</label>
                                  </div>
                                </IonCol>
                              </IonRow>
                              <IonRow className="form-item-card-time-row">
                                {reservationDataLogs[
                                  currentYear + "-" + currentMonth + "-" + selectedDate
                                ]["dinner"].map((data, index) => {
                                  return (
                                    <IonCol size="4" size-sm key={index}>
                                      <button
                                        size="large"
                                        onClick={() => {
                                          setSelectedTime(data);
                                        }}
                                        className={
                                          data === selectedTime
                                            ? "form-item-card-time active"
                                            : "form-item-card-time"
                                        }
                                      >
                                        {data}
                                      </button>
                                    </IonCol>
                                  );
                                })}
                              </IonRow>
                            </div>}
                  </> :
                    <>
                      {/* Loading Time Slot **/ }
                     
                      <div className="form-item-card-skeleton">
                        <div className="form-item-card-label">
                          <label>{i18next.t('Pages.TableReservation.Form.TimeSlot.Lunch')}</label>
                        </div>
                        <IonRow className="form-item-card-time-row">
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                          <IonCol size="3" size-sm>
                            <div className="app-block-skeleton">
                              <IonSkeletonText animated />
                            </div>
                          </IonCol>
                        </IonRow>
                      </div>
                    
                    </>
                  }
              </div>
              </div>

              {/* Step Other Information */}
              <div className="form-item">
                <div className="form-item-title">
                  <h4>{i18next.t('Pages.TableReservationAction.Accept.Step3.Title')}</h4>
                  <p>{i18next.t('Pages.TableReservationAction.Accept.Step3.Subtitle')}</p>
                </div>
                <div className="form-input">
                  <IonTextarea
                    ref={input}
                    placeholder=""
                    onIonChange={(e) => {
                      setAcceptComment(e.detail.value);
                    }}
                  />
                </div>
              </div>

              {/* </IonItem> */}
              {1 == 2 && <IonItem>
                <IonLabel position="stacked">PrePayment Amount</IonLabel>
                <IonInput
                  value={prePaymentPrice}
                  placeholder="Pre Payment Price"
                  onIonChange={(e) => {
                    setPrePaymentPrice(e.detail.value);
                  }}
                />
              </IonItem>}
            </div>
          </div>
        </IonContent>
        <IonFooter className="app-modal-footer">
          <IonToolbar color="white">
            <div className="app-modal-footer-submit">
              <button className="btn btn-simple" onClick={() => acceptDismiss()}>{i18next.t('Pages.TableReservationAction.Accept.CancelButton')}</button>
              <button className="btn btn-colored btn-loading" onClick={() => acceptReservation()}>
                {acceptLoading ? <IonSpinner name="dots" /> : i18next.t('Pages.TableReservationAction.Accept.AcceptButton')}
              </button>
            </div>
          </IonToolbar>
        </IonFooter>
        {/* <IonLoading
          cssClass="my-custom-class"
          isOpen={acceptLoading}
          message={"Please wait..."}
          duration={5000}
        /> */}
      </IonModal>
      {(checkHasCapability(state.userData, "confirm_reservation")) && (
        
        <IonFooter className="ion-no-border">
          <IonToolbar>
            {(reservationData.status != 'accepted' && reservationData.status != 'rejected') ? 
              <>
                <IonRow>
                  <IonCol size="6">
                    <button
                      id="decline-modal"
                      className="btn btn-colored btn-colored-danger"
                      onClick={() => {
                        setRejectModal(true);
                      }}
                    >
                      Rifiuta
                    </button>
                  </IonCol>
                  <IonCol>
                    <button id="accept-modal" className="btn btn-colored" onClick={() => {
                      setAcceptModal(true);
                    }}>
                      Accetta
                    </button>
                  </IonCol>
                </IonRow>
              </> : <>
                <IonRow>
                  <IonCol size="6">
                      Status key 
                  </IonCol>
                </IonRow>
              </>
            }

          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default TableReservationList;
