import HomeComponent from '../components/HomeComponent';

const Home = () => {

  return (
    <HomeComponent></HomeComponent>
  );
};

export default Home;
