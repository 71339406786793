import {
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonMenuButton,
  IonToolbar,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from "@ionic/react";
import { menuController } from "@ionic/core/components";
import "@ionic/react/css/core.css";
import { closeCircle } from "ionicons/icons";
import React, { useContext, useState, useEffect } from "react";
import SvgItem from "../SvgItem";
import { readNotification, listNotification } from "../../services/api";
import { AppContext } from "../../store/State";
import { Link } from "react-router-dom";
import { getNotificationUrl } from "../../services/notifications";

const NotificationMenu = () => {
  const { dispatch, state } = useContext(AppContext);
  const [notifications, setNotifications] = useState([]);
  const removeNotification = async (notifications_id) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => {
        return notification.id != notifications_id;
      })
    );
    let data = {
      id: notifications_id,
    };
    let response = await readNotification(data);
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      getAllNotifications();
    }
  }, [state.notifications, state.isAuthenticated]);

  const getAllNotifications = async () => {
    let response = await listNotification();
    if (response.status == 200) {
      setNotifications(response.data.data);
    }
  };


  const clickOnNotification = (notification) => {
    removeNotification(notification.id);
    menuController.toggle();
  };

  return (
    <IonMenu side="start" menuId="notification" contentId="main-content">
      <IonHeader className="app-header-menu ion-no-border" translucent={false}>
        <IonToolbar>
          <IonMenuButton slot="end" color="dark" menu="notification">
            <SvgItem type={"close"} />
          </IonMenuButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-side-nav-logo">
          <SvgItem type={"logo"} />
        </div>
        <div className="app-page-wrapper app-page-menu">
          <div className="app-page-content">
            <div className="app-notification">
              <div className="app-notification-list">
                {notifications &&
                  notifications.length > 0 &&
                  notifications.map((notification, index) => {
                    return notification.is_read == "0" ? (
                      <IonItemSliding slidingItem>
                        <IonItemOptions side="start">
                          <IonItemOption
                            color="danger"
                            onClick={(e) => removeNotification(notification.id)}
                          >
                            Delete
                          </IonItemOption>
                        </IonItemOptions>
                        <IonItem>
                          <Link
                            to={getNotificationUrl(notification.data)}
                            onClick={() => clickOnNotification(notification)}
                          >
                            <div className="app-notification-item">
                              <div className="app-notification-item-wrapper">
                                <div className="app-notification-item-icon"></div>
                                <div className="app-notification-content">
                                  <p className="title">
                                    {notification.notification_header}
                                  </p>
                                  <p className="content">
                                    {notification.notification_text}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </IonItem>
                        <IonItemOptions side="end">
                          <IonItemOption
                            color="danger"
                            onClick={(e) => removeNotification(notification.id)}
                          >
                            Delete
                          </IonItemOption>
                        </IonItemOptions>
                      </IonItemSliding>
                    ) : (
                      ""
                    );
                  })}
                {notifications.length == 0 && <> <IonItem>No notification</IonItem></>}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default NotificationMenu;
