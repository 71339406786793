import {
  IonContent,
  IonFooter,
  IonIcon,
  IonPage, IonRow,
  IonToolbar,
  IonCol
} from "@ionic/react";
import { snow, tvOutline, wifi } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from "swiper/react";
import { detailRoom } from "../../services/api";
import HeaderBackComponent from "../HeaderBackComponent";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";

const RoomDetailsComponent = () => {
  const history = useHistory();
  const { id } = useParams();
  const [roomsDetails, setRoomsDetails] = useState({});
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await detailRoom(id)
        .then(result => {
          if (result.data.statusCode === 200) {
            setRoomsDetails(result.data.data);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          setShowLoading(false);
        });
    })();
  }, []);
  const checkAvailability = () => {
    history.push("/tablereservation");
  };

  return (
    <IonPage>
      {showLoading && <>
        {Platform.OS == "web" && (
          <>
            <div className="page-content-loader">
              <div className="page-content-loader-icon">
                <LottieWeb
                  source={require("../../assets/lottie/loader/loader.json")}
                  autoPlay
                  loop
                  height={120}
                  width={120}
                />
              </div>
              <div className="page-content-loader-text">
                <h3>Stiamo caricando le camerate...</h3>
              </div>
            </div>
          </>
        )}
        {(Platform.OS == "android" ||
          Platform.OS == "ios") && (
            <>
              <div className="page-content-loader">
                <div className="page-content-loader-icon">
                  <LottieWeb
                    source={require("../../assets/lottie/loader/loader.json")}
                    autoPlay
                    loop
                    height={120}
                    width={120}
                  />
                </div>
                <div className="page-content-loader-text">
                  <h3>Stiamo caricando la camera...</h3>
                </div>
              </div>
            </>
          )}
      </>}
      {roomsDetails && roomsDetails.ID && (
        <>
          <HeaderBackComponent
            title=""
            backButton="/rooms"
            backButtonType="white"
            type="transparent"
          />
          <IonContent fullscreen>
            {console.log(roomsDetails)}
            <div className="hero-gallery-room">
              <div className="hero-room-overlay"></div>
              <div className="room-hero">
                <div className="room-hero-details">
                  <div className="hero-item-detail">
                    <div className="label">{roomsDetails.details_room.person} posti letto</div>
                  </div>
                  <div className="hero-item-detail">
                    <div className="label">{roomsDetails.details_room.mq} MQ</div>
                  </div>
                </div>
                <div className="room-hero-title">
                  <h1>{roomsDetails.title}</h1>
                </div>
                <div className="gallery-room-thumbs-container">
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={roomsDetails.gallery_room_count}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="gallery-room-thumbs"
                  >
                    {roomsDetails.gallery_room &&
                      roomsDetails.gallery_room.map((image) => {
                        return (
                          <SwiperSlide>
                            <div className="thumbs-item">
                              <img src={image} alt="" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="gallery-room-main"
              >
                {roomsDetails.gallery_room &&
                  roomsDetails.gallery_room.map((image) => {
                    return (
                      <SwiperSlide>
                        <img src={image} alt="" />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
            <div className="room-details">
              <div className="room-services">
                <div className="headline">
                  <p>I servizi</p>
                </div>
                <div className="room-services-list">
                  {roomsDetails.room_services &&
                    roomsDetails.room_services.map((term) => (
                      <>
                        {term.slug === "tv-satellitare" && (
                          <>
                            <div className="room-services-item">
                              <IonIcon icon={tvOutline}></IonIcon> {term.name}
                            </div>
                          </>
                        )}
                        {term.slug === "wifi-gratuito" && (
                          <>
                            <div className="room-services-item">
                              <IonIcon icon={wifi}></IonIcon> {term.name}
                            </div>
                          </>
                        )}
                        {term.slug === "aria-condizionata" && (
                          <>
                            <div className="room-services-item">
                              <IonIcon icon={snow}></IonIcon> {term.name}
                            </div>
                          </>
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div className="room-description">
                <div className="headline">
                  <p>La camera</p>
                </div>
                <div className="room-description-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: roomsDetails.room_description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </IonContent>

          <IonFooter className="app-footer">
            {/* <IonToolbar>
              <IonRow>
                <p>
                  Starting from <br />
                  <b>{roomsDetails.room_price} &euro; / Night</b>
                </p>
              </IonRow>
              <IonButton slot="end" shape="round" onClick={checkAvailability}>
                Availability
              </IonButton>
            </IonToolbar> */}
            {/* <IonToolbar>
              <IonRow>
                <IonCol size="6">
                  <div className="app-footer-room-cost">
                    <div className="app-footer-label">Riepilogo</div>
                    <div className="app-footer-room-price">
                      75€/persona
                    </div>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <button
                    className="btn btn-colored"
                    //disabled={isLoading}
                    shape="round"
                    onClick={checkAvailability}
                  >
                    Prenota ora
                  </button>
                </IonCol>
              </IonRow>
            </IonToolbar> */}
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default RoomDetailsComponent;
