import { IonImg, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import { AppContext } from '../../store/State';
import SvgItem from '../SvgItem';
import { Link } from 'react-router-dom';
import i18next from "i18next";
//import './index.css';

const ServiceCard = () => {

  const { state } = useContext(AppContext);

  return (
    <div className="services-block">
      <div className="services-title">
        <h3>{i18next.t('Pages.Home.Services.Title')}</h3>
      </div>
      <div className="services-block-cards">
        <div className="services-block-cards-item">
          <Link href={'/rooms'}>
            <a>
              <div className="services-block-cards-item-wrapper service-block-cards-gradient-1">
                <div className="services-block-cards-item-icon">
                  <SvgItem type={'table-booking'}/>
                </div>
                <div className="services-block-cards-item-content">
                  <h3>{i18next.t('Pages.Home.Services.TableBooking')}</h3>
                </div>
              </div>
            </a>
          </Link>
        </div>
        <div className="services-block-cards-item">
          <div className="services-block-cards-item-wrapper service-block-cards-gradient-2">
            <div className="services-block-cards-item-icon">
              <SvgItem type={'rooms'}/>
            </div>
            <div className="services-block-cards-item-content">
              <h3>{i18next.t('Pages.Home.Services.RoomBooking')}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;

