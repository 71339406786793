import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToolbar,
  IonRadioGroup,
  IonRadio,
  IonAvatar,
  IonSkeletonText,
} from "@ionic/react";
import {
  add,
  addSharp,
  arrowBack,
  arrowDown,
  remove,
  removeSharp,
} from "ionicons/icons";
import React, { useContext, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { bookTable, getReservationData } from "../../services/api";
import HeaderBackComponent from "../HeaderBackComponent";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/de";
import { AppContext } from "../../store/State";
import { Link, useHistory } from "react-router-dom";
import i18next from "i18next";

const TableReservationComponent = () => {
  moment.locale(i18next.language);

  const history = useHistory();
  const { state } = useContext(AppContext);
  let [personCount, setPersonCount] = useState(0);
  let [childrenCount, setChildrenCount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(
    state && state.userData ? state.userData.email : ""
  );
  const [additionalNote, setAdditionalNote] = useState("");
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [calenderMonth, setCalenderMonth] = useState(
    moment().format("MM/YYYY")
  );
  const [selectedDate, setDate] = useState(moment().format("DD"));
  const [selectedTime, setSelectedTime] = useState("");
  const [reservationData, setReservationData] = useState({});
  const [reservationDates, setReservationDates] = useState([]);
  const [fullDate, setFullDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    getReservationData()
      .then((response) => {
        setReservationData(response.data.data);
      })
      .catch((error) => alert(error))
      .finally();
  }, []);

  useEffect(() => {
    if (currentYear && currentMonth && selectedDate) {
      setFullDate(currentYear + "-" + currentMonth + "-" + selectedDate);
    }
  }, [currentYear, currentMonth, selectedDate]);

  useEffect(() => {
    setReservationDates(Object.keys(reservationData));
  }, [reservationData]);

  const handleIncrement = () => {
    setPersonCount(++personCount);
  };

  const handleDecrement = () => {
    if (personCount > 0) {
      setPersonCount(--personCount);
    }
  };

  const handleChildrenIncrement = () => {
    setChildrenCount(++childrenCount);
  };

  const handleChildrenDecrement = () => {
    if (childrenCount > 0) {
      setChildrenCount(--childrenCount);
    }
  };

  const handleTableBook = () => {
    if (!firstName) {
      setMessage("Please enter a valid first name");
      setIserror(true);
      return;
    }

    if (!lastName) {
      setMessage("Please enter a valid last name");
      setIserror(true);
      return;
    }

    const tableData = {
      name: firstName,
      surname: lastName,
      email: email,
      phone: phone,
      date: currentYear + "-" + currentMonth + "-" + selectedDate,
      time: selectedTime,
      number_of_adults: personCount,
      number_of_child: childrenCount,
      other_information: additionalNote,
      lang: "en",
    };

    setIsLoading(true);

    bookTable(tableData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success ...!");
          history.push("/tablereservation-list");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setIserror(true);
        setIsLoading(false);
      });
  };
  const currentDay = moment();
  let currentDayClone = currentDay;
  let bookableMonth = [];
  bookableMonth[currentDay.format("MM/YYYY")] = currentDay.format("MMMM");
  {
    for (var i = 0; i < 6; i++) {
      currentDayClone = currentDayClone.add(1, "M");
      bookableMonth[currentDayClone.format("MM/YYYY")] =
        currentDayClone.format("MMMM");
    }
  }

  const calenderMonthMoment = moment({
    year: currentYear,
    month: currentMonth - 1,
    day: 1,
  });
  return (
    <IonPage>
      <HeaderBackComponent
        title={i18next.t('Pages.TableReservation.Header.Title')}
        backButton="/home"
      ></HeaderBackComponent>
      <IonContent fullscreen className="table-reservation">
        <div className="app-page-wrapper">
          {/* <IonItem>
            <Link to="/roomdetails">
              <IonIcon size="small" color="dark" icon={arrowBack} />
            </Link>
            Table Reservation
          </IonItem> */}
          <div className="form-wrapper">
            <IonGrid className="form-title">
              <IonItem lines="none">
                <IonLabel>{i18next.t('Pages.TableReservation.Form.Person.StepTitle')}</IonLabel>
              </IonItem>
            </IonGrid>
            <IonGrid className="form-item form-wrapper-input-white">
              <IonCard className="form-item-card">
                <IonRow className="form-item-card-row">
                  <IonCol size="5" size-sm>
                    <div className="form-item-card-label">
                      <label>{i18next.t('Pages.TableReservation.Form.Person.Adult')}</label>
                    </div>
                  </IonCol>
                  <IonCol size="7" size-sm>
                    <div className="form-item-card-qty">
                      <div className="form-item-card-qty-item">
                        <IonIcon icon={removeSharp} onClick={handleDecrement} />
                      </div>
                      <div className="form-item-card-qty-item form-item-card-qty-value">
                        {personCount}
                      </div>
                      <div className="form-item-card-qty-item">
                        <IonIcon icon={addSharp} onClick={handleIncrement} />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="form-item-card-row">
                  <IonCol size="5" size-sm>
                    <div className="form-item-card-label">
                      <label>{i18next.t('Pages.TableReservation.Form.Person.Children')}</label>
                    </div>
                  </IonCol>
                  <IonCol size="7" size-sm>
                    <div className="form-item-card-qty">
                      <div className="form-item-card-qty-item">
                        <IonIcon
                          icon={remove}
                          onClick={handleChildrenDecrement}
                        />
                      </div>
                      <div className="form-item-card-qty-item form-item-card-qty-value">
                        {childrenCount}
                      </div>
                      <div className="form-item-card-qty-item">
                        <IonIcon icon={add} onClick={handleChildrenIncrement} />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonGrid>
            <IonGrid className="form-title">
              <IonItem lines="none">
                <IonLabel>{i18next.t('Pages.TableReservation.Form.DateSlot.StepTitle')}</IonLabel>
              </IonItem>
            </IonGrid>
            <IonGrid className="form-item">
              <IonCard className="form-item-card">
                <IonRow className="form-item-card-row no-bd">
                  <IonCol size="4" size-sm>
                    <div className="form-item-card-label">
                      <label>{i18next.t('Pages.TableReservation.Form.DateSlot.Month')}</label>
                    </div>
                  </IonCol>
                  <IonCol size="8" size-sm>
                    <div className="form-item-card-label">
                      <div className="form-item-card-select">
                        <IonSelect
                          interface="popover"
                          class="ion-select-overflow"
                          placeholder="Select Month"
                          value={calenderMonth}
                          onIonChange={(e) => {
                            let allData = e.detail.value.split("/");
                            setCurrentMonth(allData[0]);
                            setCurrentYear(allData[1]);
                            setCalenderMonth(e.detail.value);
                            setSelectedTime("");
                            setDate("");
                          }}
                        >
                          {bookableMonth &&
                            Object.keys(bookableMonth).length > 0 &&
                            Object.keys(bookableMonth).map(
                              (monthData, index) => {
                                return (
                                  <IonSelectOption
                                    key={index}
                                    value={monthData}
                                  >
                                    {bookableMonth[monthData]}
                                  </IonSelectOption>
                                );
                              }
                            )}
                        </IonSelect>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>

                <div className="form-item-card-slider-day">
                  <Swiper
                    spaceBetween={0}
                    initialSlide={selectedDate - 4}
                    slidesPerView={7}
                    loop={true}
                  >
                    {[...Array(31)].map((day, index) => {
                      if (calenderMonthMoment.format("MM") != currentMonth) {
                        return <></>;
                      }
                      let data = calenderMonthMoment.format("DD");
                      let isDisable = false;
                      if (
                        !reservationDates.includes(
                          currentYear + "-" + currentMonth + "-" + data
                        )
                      ) {
                        isDisable = true;
                      }
                      let weekDay = calenderMonthMoment.format("ddd");
                      calenderMonthMoment.add(1, "d");
                      return (
                        <SwiperSlide key={index}>
                          <button
                            className={
                              data === selectedDate
                                ? "form-item-card-day form-item-card-day-selected"
                                : "form-item-card-day"
                            }
                            disabled={
                              (data < moment().format("DD") &&
                                calenderMonthMoment.format("MM") ==
                                moment().format("MM")) ||
                              isDisable
                            }
                            //size="large"
                            //color={(data === selectedDate) ? "success" : ""}
                            onClick={() => {
                              setDate(data);
                            }}
                          >
                            <div className="form-item-card-day__inner">
                              <span className="weekday">{weekDay}</span>
                              <span className="data">{data}</span>
                            </div>
                          </button>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </IonCard>
            </IonGrid>
            {(reservationData[
              currentYear + "-" + currentMonth + "-" + selectedDate
            ] &&
              (reservationData[
                currentYear + "-" + currentMonth + "-" + selectedDate
              ]["lunch"].length > 0 || reservationData[
                currentYear + "-" + currentMonth + "-" + selectedDate
              ]["dinner"].length > 0)) ? <><IonGrid className="form-title">
                <IonItem lines="none">
                  <IonLabel>{i18next.t('Pages.TableReservation.Form.TimeSlot.StepTitle')}</IonLabel>
                </IonItem>
              </IonGrid>
              <IonGrid className="form-item">
                <IonCard className="form-item-card">
                  {reservationData[
                    currentYear + "-" + currentMonth + "-" + selectedDate
                  ] &&
                    reservationData[
                    currentYear + "-" + currentMonth + "-" + selectedDate
                    ]["lunch"] &&
                    reservationData[
                      currentYear + "-" + currentMonth + "-" + selectedDate
                    ]["lunch"].length > 0 && <div className="form-item-card-row">
                      <IonRow className="form-item-card-row no-bd">
                        <IonCol size="12" size-sm>
                          <div className="form-item-card-label">
                            <label>{i18next.t('Pages.TableReservation.Form.TimeSlot.Lunch')}</label>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        {reservationData[
                          currentYear + "-" + currentMonth + "-" + selectedDate
                        ]["lunch"].map((data, index) => {
                          return (
                            <IonCol size="3" size-sm key={index}>
                              <button
                                //size="large"
                                onClick={() => {
                                  setSelectedTime(data);
                                }}
                                className={
                                  data === selectedTime
                                    ? "form-item-card-time active"
                                    : "form-item-card-time"
                                }
                              >
                                {data}
                              </button>
                            </IonCol>
                          );
                        })}
                      </IonRow>
                    </div>}
                  {reservationData[
                    currentYear + "-" + currentMonth + "-" + selectedDate
                  ] &&
                    reservationData[
                    currentYear + "-" + currentMonth + "-" + selectedDate
                    ]["dinner"] &&
                    reservationData[
                      currentYear + "-" + currentMonth + "-" + selectedDate
                    ]["dinner"].length > 0 && <div className="form-item-card-row">
                      <IonRow className="form-item-card-row no-bd">
                        <IonCol size="12" size-sm>
                          <div className="form-item-card-label">
                            <label>{i18next.t('Pages.TableReservation.Form.TimeSlot.Dinner')}</label>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        {reservationData[
                          currentYear + "-" + currentMonth + "-" + selectedDate
                        ]["dinner"].map((data, index) => {
                          return (
                            <IonCol size="3" size-sm key={index}>
                              <button
                                size="large"
                                onClick={() => {
                                  setSelectedTime(data);
                                }}
                                className={
                                  data === selectedTime
                                    ? "form-item-card-time active"
                                    : "form-item-card-time"
                                }
                              >
                                {data}
                              </button>
                            </IonCol>
                          );
                        })}
                      </IonRow>
                    </div>}
                </IonCard>
              </IonGrid>
            </> :
              <>
                <IonGrid className="form-title">
                  <IonItem lines="none">
                    <IonLabel>Stiamo caricando gli orari...</IonLabel>
                  </IonItem>
                </IonGrid>
                <IonGrid className="form-item">
                  <IonCard className="form-item-card form-item-card-skeleton">
                    <IonRow className="form-item-card-time-row">
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                      <IonCol size="3" size-sm>
                        <div className="app-block-skeleton">
                          <IonSkeletonText animated />
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonGrid>
              </>
            }
            {/* {reservationData &&
              reservationData[fullDate] &&
              reservationData[fullDate].event && (
                <>
                  <IonGrid className="form-title">
                    <IonItem lines="none">
                      <IonLabel>Events</IonLabel>
                    </IonItem>
                  </IonGrid>
                  <IonGrid className="form-item">
                    <IonCard className="form-item-card">
                      <IonRow className="form-item-card-row no-bd">
                        <IonCol size="12" size-sm>
                          <div className="form-item-card-label">
                            <label>
                              {reservationData[fullDate].event.title}
                            </label>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Description</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <p>{reservationData[fullDate].event.description}</p>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Event Date</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <p>
                          {reservationData[fullDate].event.date.start}{" "}
                          {" ---> "} {reservationData[fullDate].event.date.end}
                        </p>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Adult Menu</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <IonCard className="form-item-card">
                          <IonRow className="form-item-card-time-row">
                            <IonCol size="12" size-sm>
                              <div className="form-item-card-label">
                                <label>
                                  {
                                    reservationData[fullDate].event.adult_menu
                                      .title
                                  }
                                </label>
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Description</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <p>
                              {
                                reservationData[fullDate].event.adult_menu
                                  .description
                              }
                            </p>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Menu Items</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            {reservationData[fullDate].event.adult_menu
                              .products_food_menu &&
                              reservationData[
                                fullDate
                              ].event.adult_menu.products_food_menu.map(
                                (menu_item) => {
                                  return (
                                    <IonCard className="form-item-card">
                                      <IonRow className="form-item-card-time-row">
                                        <IonCol size="12" size-sm>
                                          <div className="form-item-card-label">
                                            <label>{menu_item.title}</label>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow className="form-item-card-time-row">
                                        <h6>Description</h6>
                                      </IonRow>
                                      <IonRow className="form-item-card-time-row">
                                        <p>{menu_item.description}</p>
                                      </IonRow>
                                      {menu_item.category && (
                                        <>
                                          <IonRow className="form-item-card-time-row">
                                            <h6>Category</h6>
                                          </IonRow>
                                          <IonRow className="form-item-card-time-row">
                                            {menu_item.category.map((cat) => {
                                              return <p>{cat.name}</p>;
                                            })}
                                          </IonRow>
                                        </>
                                      )}
                                    </IonCard>
                                  );
                                }
                              )}
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Price</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <p>
                              {
                                reservationData[fullDate].event.adult_menu
                                  .sales_price_food_menu
                              }{" "}
                              €{" "}
                            </p>
                          </IonRow>
                        </IonCard>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Children Menu</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <IonCard className="form-item-card">
                          <IonRow className="form-item-card-time-row">
                            <IonCol size="12" size-sm>
                              <div className="form-item-card-label">
                                <label>
                                  {
                                    reservationData[fullDate].event
                                      .children_menu.title
                                  }
                                </label>
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Description</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <p>
                              {
                                reservationData[fullDate].event.children_menu
                                  .description
                              }
                            </p>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Menu Items</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            {reservationData[fullDate].event.children_menu
                              .products_food_menu &&
                              reservationData[
                                fullDate
                              ].event.children_menu.products_food_menu.map(
                                (menu_item) => {
                                  return (
                                    <IonCard className="form-item-card">
                                      <IonRow className="form-item-card-time-row">
                                        <IonCol size="12" size-sm>
                                          <div className="form-item-card-label">
                                            <label>{menu_item.title}</label>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow className="form-item-card-time-row">
                                        <h6>Description</h6>
                                      </IonRow>
                                      <IonRow className="form-item-card-time-row">
                                        <p>{menu_item.description}</p>
                                      </IonRow>
                                      {menu_item.category && (
                                        <>
                                          <IonRow className="form-item-card-time-row">
                                            <h6>Category</h6>
                                          </IonRow>
                                          <IonRow className="form-item-card-time-row">
                                            {menu_item.category.map((cat) => {
                                              return <p>{cat.name}</p>;
                                            })}
                                          </IonRow>
                                        </>
                                      )}
                                    </IonCard>
                                  );
                                }
                              )}
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <h6>Price</h6>
                          </IonRow>
                          <IonRow className="form-item-card-time-row">
                            <p>
                              {
                                reservationData[fullDate].event.children_menu
                                  .sales_price_food_menu
                              }{" "}
                              €{" "}
                            </p>
                          </IonRow>
                        </IonCard>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Adult Price</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <p>{reservationData[fullDate].event.adult_price} € </p>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <h6>Child Price</h6>
                      </IonRow>
                      <IonRow className="form-item-card-time-row">
                        <p>{reservationData[fullDate].event.child_price} € </p>
                      </IonRow>
                    </IonCard>
                  </IonGrid>
                </>
              )} */}
            <IonGrid className="form-title">
              <IonItem lines="none">
                <IonLabel>{i18next.t('Pages.TableReservation.Form.OtherInformation.StepTitle')}</IonLabel>
              </IonItem>
            </IonGrid>
            <IonGrid className="form-item">
              <IonCard className="form-item-card">
                <IonRow>
                  <IonCol>
                    <IonAlert
                      isOpen={iserror}
                      onDidDismiss={() => setIserror(false)}
                      cssClass="my-custom-class"
                      header={"Error!"}
                      message={message}
                      buttons={["Dismiss"]}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="form-item-card-row form-item-card-row-form no-bd">
                  <IonCol size="6" size-sm>
                    <div className="form-input">
                      <label>{i18next.t('Common.Form.Name')} *</label>
                      <IonInput
                        type="text"
                        placeholder=""
                        value={firstName}
                        onIonChange={(e) => setFirstName(e.detail.value)}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <div className="form-input">
                      <label>{i18next.t('Common.Form.Surname')} *</label>
                      <IonInput
                        type="text"
                        placeholder=""
                        value={lastName}
                        onIonChange={(e) => setLastName(e.detail.value)}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="12">
                    <div className="form-input">
                      <label>{i18next.t('Common.Form.Email')} *</label>
                      <IonInput
                        type="text"
                        placeholder=""
                        value={email}
                        onIonChange={(e) => setEmail(e.detail.value)}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="12">
                    <div className="form-input">
                      <label>{i18next.t('Common.Form.Phone')} *</label>
                      <IonInput
                        type="tel"
                        placeholder=""
                        value={phone}
                        onIonChange={(e) => setPhone(e.detail.value)}
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="form-input">
                      <label>{i18next.t('Pages.TableReservation.Form.OtherInformation.Note')}</label>
                      <IonTextarea
                        type="text"
                        placeholder={i18next.t('Pages.TableReservation.Form.OtherInformation.NotePlaceholder')}
                        value={additionalNote}
                        onIonChange={(e) => setAdditionalNote(e.detail.value)}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <IonFooter className="app-footer">
        <IonToolbar>
          <IonRow>
            {/* <IonCol size="6">
              <div className="app-footer-label">Riepilogo</div>
            </IonCol> */}
            <IonCol size="3"></IonCol>
            <IonCol size="6">
              <button
                className="btn btn-colored btn-loading"
                disabled={isLoading}
                shape="round"
                onClick={handleTableBook}
              >

                {isLoading ? <IonSpinner name="dots" /> : i18next.t('Pages.TableReservation.Form.Submit')}
              </button>
            </IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default TableReservationComponent;
