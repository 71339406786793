import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner } from '@ionic/react';
import { arrowBack, mailOutline } from "ionicons/icons";
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { forgotPassword } from '../../services/api';
import SmallHeader from '../SmallHeader';

const ForgotPasswordComponent = () => {

  const history = useHistory();
  const [email, setEmail] = useState('');
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = () => {
    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      return;
    }

    const forgotPasswordData = {
      "email": email
    }

    setIsLoading(true);

    forgotPassword(forgotPasswordData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          history.push('/resetpassword/?email=' + email);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log("error catch search:", err.message);
        setMessage("Something went wrong! Please try again later");
        setIserror(true);
        setIsLoading(false);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="app-page">
          <SmallHeader />
          <div className="app-page-wrapper app-page-wrapper-login-routes">
            <div className="app-page-content">
              <div className="app-headline">
                <h1>Hai dimenticato la password?</h1>
                <p>Inserisci la tua email ed avvia la procedura di modifica password.</p>
              </div>
              <div className="form-wrapper form-wrapper-input-white">
                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input">
                        <IonInput type="email" placeholder='Email'
                          value={email}
                          onIonChange={(e) => setEmail(e.detail.value)} />
                        <div className="form-icon">
                          <IonIcon icon={mailOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item">
                  <IonRow>
                    <IonCol size="12">
                      <button className='btn btn-colored'
                        disabled={isLoading}
                        onClick={handleForgotPassword}
                      >
                        Procedi
                        {isLoading ? <IonSpinner name="dots" /> : ""}
                      </button>
                    </IonCol>
                    <IonCol size="12">
                      <div className="app-alert">
                        <IonAlert
                          isOpen={iserror}
                          onDidDismiss={() => setIserror(false)}
                          cssClass="my-custom-class"
                          header={"Error!"}
                          message={message}
                          buttons={["Dismiss"]}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      {/* <IonContent fullscreen className="ion-padding ion-text-center">
        <IonItem>
          <Link to="/login">
            <IonIcon size='large' color='dark' icon={arrowBack} />
          </Link>
          <IonLabel>Sign In</IonLabel>
        </IonItem>
        <IonGrid>
          <IonItem lines='none'>
            <IonLabel>Please enter your email</IonLabel>
          </IonItem>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput type="email" placeholder='Email'
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)} />
                <IonIcon icon={mailOutline} />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton disabled={isLoading} shape="round" onClick={handleForgotPassword}>
                Forgot Password
                {isLoading ? <IonSpinner name="dots" /> : ''}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent> */}

    </IonPage>
  );
};

export default ForgotPasswordComponent;

