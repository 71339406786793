import {
  IonCard,
  IonCardContent,
  IonCol,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getWeatherDetails } from "../../services/api";
import { HTTPS } from "../../services/api/Api.Constant";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";
import i18next from "i18next";
const TodayWeatherComponent = () => {
  const currentHour = new Date().getHours();

  const slideOpts = {
    spaceBetween: 50,
    slidesPerView: 3,
    loop: true,
    initialSlide: currentHour,
  };

  const [forecastDayWeather, setForecastDayWeather] = useState([]);
  const weatherSlides = useRef(document.createElement("ion-slides"));

  useEffect(() => {
    getWeatherDetails()
      .then((response) => {
        setForecastDayWeather(response.data.forecast.forecastday);
      })
      .catch((error) => alert(error))
      .finally();
  }, []);

  const handleSlideChange = (event) => {
  };

  const getLottieJSON = (code, hour, activeHour) => {
    let data = {};

    if (parseInt(hour) > 20 || parseInt(hour) < 6) {
      /**night condition*/
      if ((code == '1003')) {
        data = require("../../assets/lottie/weather/cloudy-moon.json")
      }
      if ((code == '1000')) {
        data = require("../../assets/lottie/weather/night.json")
      }
    } else {
      /*day condition*/
      if ((code == '1003')) {
        data = require("../../assets/lottie/weather/cloudy-sunny.json")
      }
      if ((code == '1000')) {
        data = require("../../assets/lottie/weather/sun.json")
      }
    }

    if ((code == '1006' || code == '1009')) {
      data = require("../../assets/lottie/weather/cloud_2.json")
    }

    if ((code == '1030' || code == '1135' || code == '1147')) {
      if (activeHour) {
        data = require("../../assets/lottie/weather/fog_2.json")
      } else {
        data = require("../../assets/lottie/weather/fog.json")
      }
    }

    if (code == '1261') {
      data = require("../../assets/lottie/weather/hailstorm-light.json")
    }

    if (code == '1264') {
      data = require("../../assets/lottie/weather/hailstorm-moderate.json")
    }

    if (code == '1237') {
      data = require("../../assets/lottie/weather/hailstorm.json")
    }

    if (code == '1072') {
      if (activeHour) {
        data = require("../../assets/lottie/weather/rain_snow-light_2.json")
      } else {
        data = require("../../assets/lottie/weather/rain_snow-light.json")
      }
    }

    if (code == '1273') {
      data = require("../../assets/lottie/weather/rain_thunder-light.json")
    }

    if ((code == '1063' || code == '1150' || code == '1153' || code == '1168' || code == '1180' || code == '1183' || code == '1198' || code == '1240')) {
      data = require("../../assets/lottie/weather/rain-light.json")
    }

    if ((code == '1186' || code == '1189' || code == '1201' || code == '1243')) {
      data = require("../../assets/lottie/weather/rain-moderate.json")
    }

    if ((code == '1171' || code == '1192' || code == '1195' || code == '1246')) {
      data = require("../../assets/lottie/weather/rain.json")
    }

    if (code == '1282') {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snow_thunder_2.json")
      } else {
        data = require("../../assets/lottie/weather/snow_thunder.json")
      }
    }

    if (code == '1279') {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snow_thunder-light_2.json")
      } else {
        data = require("../../assets/lottie/weather/snow_thunder-light.json")
      }
    }

    if ((code == '1066' || code == '1069' || code == '1204' || code == '1210' || code == '1213' || code == '1249' || code == '1255')) {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snow-light_2.json")
      } else {
        data = require("../../assets/lottie/weather/snow-light.json")
      }
    }

    if ((code == '1207' || code == '1216' || code == '1219' || code == '1252' || code == '1258')) {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snow-moderate_2.json")
      } else {
        data = require("../../assets/lottie/weather/snow-moderate.json")
      }
    }

    if (code == '1114') {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snow-wind_2.json")
      } else {
        data = require("../../assets/lottie/weather/snow-wind.json")
      }
    }

    if ((code == '1222' || code == '1225')) {
      if (activeHour) {
        data = require("../../assets/lottie/weather/snowfall_2.json")
      } else {
        data = require("../../assets/lottie/weather/snowfall.json")
      }
    }

    if (code == '1117') {
      data = require("../../assets/lottie/weather/storm.json")
    }

    if (code == '1276') {
      data = require("../../assets/lottie/weather/thunderstorm.json")
    }

    if (code == '1087') {
      data = require("../../assets/lottie/weather/thundery.json")
    }

    return data;
  }

  return (

    <div className="main-app-weather">
      <div className="main-app-section-title">
        <IonItem className="app-block" lines="none">
          <IonLabel>{i18next.t('Components.TodayWeather.Title')}</IonLabel>
          {/* <IonNote slot="end" color="#00591d">
                    Altri giorni
                </IonNote> */}
        </IonItem>
      </div>
      <div className="main-app-weather-slider">
        <div className="main-app-weather-slider-container">
          <Swiper
            spaceBetween={2}
            initialSlide={currentHour + 3}
            slidesPerView={3.5}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log("on swiper")}
            loop={true}
            className="slider-weather"
          >
            {forecastDayWeather.map((dayWeather) => {
              return dayWeather.hour.map((hourWeather, index) => {

                return (
                  <SwiperSlide
                    ref={weatherSlides}
                    onClick={() => handleSlideChange(hourWeather)}
                  >
                    <IonCard
                      className={
                        currentHour === index
                          ? "card-weather active"
                          : "card-weather"
                      }
                    >
                      <IonCardContent>
                        <IonRow className="card-weather-content">
                          <IonCol size="12">
                            <IonLabel className="card-weather-content-label">
                              {index} : 00
                            </IonLabel>
                          </IonCol>
                          <IonCol size="12">
                            <div className="card-weather-content-icon">
                              {/* <IonIcon icon={hourWeather.temp_c > 12 ? sunny : hourWeather.temp_c >= 11 ? cloudy : rainy} slot="start" size='large' /> */}

                              {/* <IonImg src={HTTPS + hourWeather.condition.icon} height='10px'></IonImg> */}

                              {Platform.OS == "web" && (
                                <>
                                  <LottieWeb
                                    source={getLottieJSON(hourWeather.condition.code, index, (currentHour === index))}
                                    autoPlay
                                    loop
                                  />
                                </>
                              )}
                              {(Platform.OS == "android" ||
                                Platform.OS == "ios") && (
                                  <>
                                    <Lottie
                                      source={getLottieJSON(hourWeather.condition.code, index, (currentHour === index))}
                                      autoPlay
                                      loop
                                    />
                                  </>
                                )}

                            </div>
                          </IonCol>
                          <IonCol size="12">
                            <IonLabel className="card-weather-content-label">
                              {hourWeather.temp_c} &#8451;
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>
                  </SwiperSlide>
                );
              });
            })}
          </Swiper>
        </div>
      </div>
    </div>

  );
};

export default TodayWeatherComponent;
