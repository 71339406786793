import {
  PushNotifications
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner
} from "@ionic/react";
import { arrowBack, lockClosedOutline, mailOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { userLogin } from "../../services/api";
import FacebookAuthService from "../../services/facebook-auth-service";
import GoogleAuthService from "../../services/google-auth-service";
import { AppContext } from "../../store/State";
import SmallHeader from '../SmallHeader';
import i18next from 'i18next';

const LoginComponent = () => {

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fcmRegistrationToken, setFcmRegistrationToken] = useState("");
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch, state } = useContext(AppContext);


  const handleLogin = () => {
    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Please enter your password");
      setIserror(true);
      return;
    }
    const loginData = {
      username: email,
      password: password,
      //device_token: fcmRegistrationToken
      device_token: state.fcmToken
    };

    setIsLoading(true);

    userLogin(loginData)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.data.token);
          dispatch({ type: "setIsAuthenticated", isAuthenticated: true });
          dispatch({ type: "setUserDetails", userData: response.data.data });
          history.push("/home");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setIserror(true);
        setIsLoading(false);
      });
  };

  return (
    <IonPage>
      {/* <HeaderBackComponent
        title=""
        backButton="/home"
        withLogo
        type="transparent"
        /> */}
      <IonContent fullscreen>
        <div className="app-page">
          <SmallHeader />
          <div className="app-page-wrapper app-page-wrapper-login-routes">
            <div className="app-page-content">
              <div className="app-headline">
                <h1>{i18next.t('Pages.Login.Header.Title')}</h1>
                <p>{i18next.t('Pages.Login.Header.Subtitle')}</p>
              </div>
              <div className="form-wrapper form-wrapper-input-white">
                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input">
                        <IonInput
                          type="email"
                          placeholder={i18next.t('Common.Form.Email')}
                          value={email}
                          onIonChange={(e) => setEmail(e.detail.value)}
                        />
                        <div className="form-icon">
                          <IonIcon icon={mailOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item">
                  <IonRow>
                    <IonCol>
                      <div className="form-input">
                        <IonInput
                          type="password"
                          placeholder={i18next.t('Common.Form.Password')}
                          value={password}
                          onIonChange={(e) => setPassword(e.detail.value)}
                        />
                        <div className="form-icon">
                          <IonIcon icon={lockClosedOutline} />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
                <div className="form-item form-item-link form-item-align-right">
                  <Link to="/forgotpassword">
                  {i18next.t('Common.Form.LostPassword')}
                  </Link>
                </div>
                <div className="form-item form-item-submit">
                  <IonRow>
                    <IonCol size="12">
                      <button className='btn btn-colored btn-loading'
                        disabled={isLoading}
                        onClick={handleLogin}
                      >
                        {isLoading ? <IonSpinner name="dots" /> : i18next.t('Common.Form.Login')}
                      </button>
                    </IonCol>
                    <IonCol size="12">
                      <div className="app-alert">
                        <IonAlert
                          isOpen={iserror}
                          onDidDismiss={() => setIserror(false)}
                          cssClass="my-custom-class"
                          header={"Error!"}
                          message={message}
                          buttons={["Dismiss"]}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
              <div className="form-social-login">
                <div className="form-social-login-title">
                  <p>{i18next.t('Common.Form.SocialLogin.Title')}</p>
                </div>
                <div className="form-social-login-item">
                  <FacebookAuthService></FacebookAuthService>
                </div>
                <div className="form-social-login-item">
                  {/* <GoogleAuthService></GoogleAuthService> */}
                </div>
              </div>
              {/* 
              <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonAlert
                        isOpen={iserror}
                        onDidDismiss={() => setIserror(false)}
                        cssClass="my-custom-class"
                        header={"Error!"}
                        message={message}
                        buttons={["Dismiss"]}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonInput
                          type="email"
                          placeholder="Email"
                          value={email}
                          onIonChange={(e) => setEmail(e.detail.value)}
                        />
                        <IonIcon icon={mailOutline} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonInput
                          type="password"
                          placeholder="Password"
                          value={password}
                          onIonChange={(e) => setPassword(e.detail.value)}
                        />
                        <IonIcon icon={lockClosedOutline} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        disabled={isLoading}
                        shape="round"
                        onClick={handleLogin}
                      >
                        Sign In
                        {isLoading ? <IonSpinner name="dots" /> : ""}
                      </IonButton>
                      <p style={{ fontSize: "medium" }}>
                        Did you forget your password?
                      </p>
                      <Link to="/forgotpassword">Forgot Password</Link>
                    </IonCol>
                  </IonRow>
                </IonGrid> */}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginComponent;
