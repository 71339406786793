import React, { useEffect, useState, useContext } from 'react';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { useHistory } from "react-router-dom";
import { listNotification } from '../../services/api';
import { Capacitor } from '@capacitor/core';
import { getNotificationUrl } from '../../services/notifications';
import { Toast } from "@capacitor/toast";


import { AppContext } from "../../store/State";

export default function PushNotificationsContainer() {
    const nullEntry = [];
    const [notifications, setnotifications] = useState(nullEntry);

    const { dispatch, state } = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        if (!isPushNotificationsAvailable) {
            return;
        }

        if (state.fcmToken != '') {
            return;
        }

        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        showToast('Push Notification permission denied');
                    }
                    else {
                        showToast('Push Notification permission granted');
                        register();
                    }
                });
            }
            else {
                register();
            }
        });
    }, [])

    const register = () => {
        PushNotifications.register();

        PushNotifications.addListener('registration',
            (token) => {
                showToast('Push registration success ' + token.value);

                dispatch({ type: "setFcmToken", fcmToken: token.value });
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            async (notification) => {
                showToast('pushNotificationReceived ' + JSON.stringify(notification));
                dispatch({ type: "getNotifications", notifications: notification });
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            async (notification_obj) => {
                showToast('pushNotificationActionPerformed ' + JSON.stringify(notification_obj));
                if (notification_obj && notification_obj.notification && notification_obj.notification.data) {
                    history.push(getNotificationUrl(notification_obj.notification.data));
                }

                let response = await listNotification();
                if (response.status == 200) {
                    dispatch({ type: "getNotifications", notifications: response.data.data });
                }
            }
        );

    }

    const showToast = async (msg) => {
        await Toast.show({
            text: msg
        })
    }
    return <></>
}


