import ForgotPasswordComponent from '../components/ForgotPasswordComponent';

const ForgotPassword = () => {

  return (
    <ForgotPasswordComponent></ForgotPasswordComponent>
  );
};

export default ForgotPassword;
