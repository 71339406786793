import axios from "axios";
import { BASE_URL } from "./Api.Constant";
import { i18n } from "../../translations/i18n";

export const userRegistration = async (data) => {
  return axios.post(BASE_URL + "/user/register?lang=" + i18n.language, data);
};

export const userLogin = async (data) => {
  return axios.post(BASE_URL + "/user/token/?lang=" + i18n.language, data);
};

export const userSocialLogin = async (data) => {
  return axios.post(BASE_URL + "/user/social?lang=" + i18n.language, data);
};

export const getBlogList = async () => {
  return (await axios.get(BASE_URL + "/blog/list/?lang=" + i18n.language + "& page=-1&size=5")).data;
};

export const getFaqList = async () => {
  return (await axios.get(BASE_URL + "/faq/list/?lang=" + i18n.language + "&page=1&size=5")).data;
};

export const forgotPassword = async (data) => {
  return axios.post(BASE_URL + "/user/reset_password?lang=" + i18n.language, data);
};

export const resetPassword = async (data) => {
  return axios.post(BASE_URL + "/user/update_password?lang=" + i18n.language, data, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const getWeatherDetails = async (days) => {
  return axios.get(
    "https://api.weatherapi.com/v1/forecast.json?key=45e5f07219bc4adebf1122721222907&q=45.68301,10.8147053&days=" +
    (days ? days : 1) +
    "&lang=" + i18n.language
  );
};

export const getReservationData = async () => {
  return axios.get(BASE_URL + "/reservation/data?lang=" + i18n.language);
};

export const bookTable = async (data) => {
  return axios.post(BASE_URL + "/reservation/create?lang=" + i18n.language, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const listTableReservation = async (page, size, filter, reservationStatus) => {
  return axios.get(BASE_URL + `/reservation/?page=${page}&size=${size}&filter=${filter}&status=${reservationStatus}&lang=${i18n.language}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const detailTableReservation = async (id) => {
  return axios.get(BASE_URL + `/reservation/${id}?lang=${i18n.language}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const rejectTableReservation = async (id, comment) => {
  return axios.post(
    BASE_URL + `/reservation/reject?lang=${i18n.language}`,
    {
      id: id,
      other_information: comment,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const acceptTableReservation = async (data) => {
  return axios.post(BASE_URL + `/reservation/accept?lang=${i18n.language}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
export const saveComment = async (data) => {
  return axios.post(BASE_URL + "/reservation/accept?lang=" + i18n.language, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const getRoomList = async () => {
  return (
    await axios.get(BASE_URL + "/room/list/?page=1&size=10&lang=" + i18n.language, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
  ).data;
};

export const detailRoom = async (id) => {
  return axios.get(BASE_URL + `/room/${id}?lang=${i18n.language}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const readNotification = async (data) => {
  return axios.post(BASE_URL + "/notification/read?lang=" + i18n.language, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const listNotification = async () => {
  return axios.get(BASE_URL + `/notification/list?lang=${i18n.language}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const getCurrentMenuData = async () => {
  return axios.get(BASE_URL + `/menu/current_menu?lang=${i18n.language}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const getCurrentMenuCategoryData = async (menu_id, category_id) => {
  return axios.get(
    BASE_URL +
    `/menu/category/?menu_id=${menu_id}&category_id=${category_id}&lang=${i18n.language}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
