import React, { createContext, useReducer } from "react";
import Reducer from "../reducer/Reducer";

let AppContext = createContext();

const initialState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : false,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    fcmToken: "",
    notifications: {}
}

function AppContextProvider(props) {
    const fullInitialState = {
        ...initialState,
    }

    let [state, dispatch] = useReducer(Reducer, fullInitialState);
    let value = { state, dispatch };


    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };

