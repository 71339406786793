export const TRANSLATIONS_EN = {
  Header: {
    Hello: "Hello EN"
  },
  Common: {
    Form: {
      Name: "Nome",
      Surname: "Cognome",
      Email: "Email",
      Phone: "Numero di telefono",
      Password: "Password",
      Country: "Paese",
      Language: "Lingua",
      Login: "Accedi",
      Register: "Registrati",
    }
  },
  Components: {
    BottomNav: {
      Home:"Home",
      TableReservation: "Prenota",
      Room: "Camere",
      Webcam: "Webcam",
      Menu: "Menu",
    },
    SideNav: {
      MyAccount: "Il mio account",
      LiveWebcam: "Live Webcam",
      Weather: "Meteo",
      TableReservation: "Prenota il tuo tavolo",
      RestaurantMenu: "Il menu del ristorante",
      Rooms: "Le camere",
      Stories: "Storis",
      Faq: "Faq",
      Logout: "Logout",
      MyReservation: "Le mie prenotazioni",
      ReservationAdmin: "Controlla prenotazioni",
    },
    TodayWeather: {
      Title: "Che tempo fa oggi?"
    }
  },
  Pages: {
    Home: {
      Header: {
        Title: "Benvenuto al",
      },
      Services: {
        Title: "Trova il tuo servizio",
        TableBooking: "Prenota un tavolo",
        RoomBooking: "Prenota una camera"
      }
    },
    Registration: {
      Header: {
        Title: "Benvenuto!",
        Subtitle: "Crea un account per accedere a tutte le funzionalità",
      }
    },
    Login: {
      Header: {
        Title: "Bentornato!",
        Subtitle: "Usa le credenziali qui sotto ed accedi al tuo account",
      }
    },
    RequireLogin: {
      Header: {
        Title: "",
        Subtitle: "",
      }
    },
    TableReservation: {
      Header: {
        Title: "Prenota un tavolo",
      },
      Form: {
        Person: {
          StepTitle: "In quanti sarete?",
          Adult: "Adulti",
          Children: "Bambini"
        },
        DateSlot: {
          StepTitle: "Quando vorresti venire?",
          Month: "Mese",
        },
        TimeSlot: {
          StepTitle: "Scegli un orario",
          Dinner: "Gli orari per la cena",
          Lunch: "Gli orari per il pranzo",
        },
        OtherInformation: {
          StepTitle: "Altre Informazioni",
          Note: "Note aggiuntive",
          NotePlaceholder: "Esempio: “Mi serve un seggiolone”, “Sono intollerante al lattosio”.",
        },
        Submit: "Prenota ora",
      }
    },
    LiveWebcam: {
      Header: {
        Title: "Live Webcam",
      }
    }
  }
};