import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { logoFacebook } from "ionicons/icons";
import React from "react";
import { userSocialLogin } from '../api';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../store/State';
import i18next from "i18next";
import {
  FacebookLogin,
  FacebookLoginResponse,
} from '@capacitor-community/facebook-login';

const FacebookAuthService = () => {
  const { dispatch, state } = useContext(AppContext);
  const history = useHistory();
  const responseFacebook = (response) => {
    console.log("Response from FacebookAuthService");
    console.log(response);
    userSocialLogin({
      email: response.email,
      name: response.name,
      device_token: state.fcmToken
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.data.token);
          dispatch({ type: "setIsAuthenticated", isAuthenticated: true });
          dispatch({ type: "setUserDetails", userData: response.data.data });
          history.push("/home");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signInWithFacebook = async () => {
    await FacebookLogin.initialize({ appId: '1137406676812495' });

    const FACEBOOK_PERMISSIONS = [
      'email',
      'public_profile'
    ];
    const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    console.log("result", result)
    if (result.accessToken) {
      // Login successful.
      const response = await FacebookLogin.getProfile({ fields: ['name', 'email'] })
      console.log(`Facebook access token is ${result}`, result);
      userSocialLogin({
        email: response.email,
        name: response.name,
        device_token: state.fcmToken
      })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            localStorage.setItem("token", response.data.data.token);
            dispatch({ type: "setIsAuthenticated", isAuthenticated: true });
            dispatch({ type: "setUserDetails", userData: response.data.data });
            history.push("/home");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          {/* <FacebookLogin
            appId="1137406676812495"
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                className="btn btn-colored btn-colored-white btn-with-icon"
                onClick={renderProps.onClick}
              >
                <IonIcon slot="start" icon={logoFacebook} color="primary" />
                <span>{i18next.t('Common.Form.SocialLogin.Facebook.Submit')}</span>
              </button>
            )}
          /> */}
          <button
            className="btn btn-colored btn-colored-white btn-with-icon"
            onClick={() => signInWithFacebook()}
          >
            <IonIcon slot="start" icon={logoFacebook} color="primary" />
            <span>{i18next.t('Common.Form.SocialLogin.Facebook.Submit')}</span>
          </button>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default FacebookAuthService;
