import React from 'react';
import TableReservationListComponent from '../components/TableReservationList';

const TableReservationList = () => {

  return (
    <TableReservationListComponent></TableReservationListComponent>
  );
};

export default TableReservationList;
