
import { IonReactRouter } from '@ionic/react-router';
import { useContext } from 'react';
import { AppContext } from '../store/State';
import AppRoutes from './AppRoutes';
const AppRouterNavigator = () => {

    const { state } = useContext(AppContext);
    return (
        <>
            <IonReactRouter>
                <AppRoutes />
            </IonReactRouter>
        </>
    );
}

export default AppRouterNavigator;
