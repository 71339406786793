import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../store/State";
import SvgItem from "../SvgItem";
  
const SmallHeader = () => {
const { state } = useContext(AppContext);

// StatusBar.setStyle({ style: Style.Light });

return (
    <div className="app-small-header">
        <div className="app-small-header-wrapper">
            <div className="app-back-button">
                <Link to={"/home"}><SvgItem type={"back"} /></Link>
            </div>
            <div className="app-small-header-logo">
                <SvgItem type={'logo'}/>
            </div>
        </div>
    </div>
);
};

export default SmallHeader;
  