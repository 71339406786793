import { menuController } from "@ionic/core/components";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonAlert,
} from "@ionic/react";
import {
  locationOutline,
  lockClosedOutline,
  logOutOutline,
  mailOutline,
  menu,
  notificationsOutline,
  personOutline,
  trashOutline,
  keyOutline,
} from "ionicons/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../store/State";
import { Link } from "react-router-dom";
import { resetPassword } from "../../services/api";
import SvgItem from "../SvgItem";
import i18next from "i18next";
const SideBarMenu = () => {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const { state } = useContext(AppContext);
  const userData = state.userData;
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [activeChild, setActieChild] = useState({})

  const handleSignIn = () => {
    menuController.close();
    history.push("/login");
  };

  const handleRegistration = () => {
    menuController.close();
    history.push("/registration");
  };
  const handleSignOut = () => {
    dispatch({ type: "setIsAuthenticated", isAuthenticated: false });
    dispatch({ type: "setUserDetails", userData: {} });
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    menuController.close();
    history.push("/home");
  };

  const closeMenu = () => {
    menuController.close();
  }

  const toggleMenuItem = (menuItemID, status) => {
    let activeChildCurrent = { ...activeChild }
    activeChildCurrent[menuItemID] = !status;
    setActieChild(activeChildCurrent);
  }

  return (
    <>
      <IonPage className="app-side-nav">
        <IonContent fullscreen>
          <div className="app-side-nav-logo">
            <SvgItem type={'logo'} />
          </div>
          {!state.isAuthenticated && (
            <IonGrid class="ion-text-center">
              <IonRow>
                <IonCol size="6" class="ion-col-center">

                  <button className="btn btn-colored"
                    onClick={handleSignIn}
                  >
                    {i18next.t('Common.Form.Login')}
                  </button>
                </IonCol>
                <IonCol size="6" class="ion-col-center">
                  <button className="btn btn-outline"
                    onClick={handleRegistration}
                  >
                    {i18next.t('Common.Form.Register')}
                  </button>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          <IonRow>
          </IonRow>

          <div className="app-navigation-menu">
            <div className="app-navigation-title">
              <h3>{i18next.t('Components.SideNav.Title')}</h3>
            </div>
            {state.isAuthenticated && (
              <div className={activeChild['myaccount'] ? 'app-navigation-menu-item first app-navigation-menu-has-child app-navigation-menu-item-open':'app-navigation-menu-item app-navigation-menu-has-child first'}
              onClick={() => {
                toggleMenuItem('myaccount', activeChild['myaccount'] ? activeChild['myaccount'] : false)
              }}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'profile'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {/* {userData.firstName} {userData.lastName} */}
                    {i18next.t('Components.SideNav.MyAccount')}
                  </div>
                  <div className="app-navigation-menu-item-arrow">
                    <SvgItem type={'arrow-down'}/>
                  </div>
                </div>
                {activeChild['myaccount'] && <>
                <div className="app-navigation-menu-item__child">
                  <Link to="/tablereservation-list" onClick={closeMenu}>
                    <div className="app-navigation-menu-item__label">
                      {i18next.t('Components.SideNav.MyReservation')}
                    </div>
                  </Link>
                </div>
                <div className="app-navigation-menu-item__child">
                  <Link to="/home" onClick={handleSignOut}>
                    <div className="app-navigation-menu-item__label">
                      {i18next.t('Components.SideNav.Logout')}
                    </div>
                  </Link>
                </div>
                </>}
              </div>
            )}
            <div className="app-navigation-menu-item">
              <Link to="/live-webcam" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'webcam'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.LiveWebcam')}
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="app-navigation-menu-item">
              <Link to="/weather" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'weather'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.Weather')}
                  </div>
                </div>
              </Link>
            </div> */}
            <div className="app-navigation-menu-item">
              <Link to="/tablereservation" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'table-booking'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.TableReservation')}
                  </div>
                </div>
              </Link>
            </div>
            <div className="app-navigation-menu-item">
              <Link to="/restaurant-menu" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'menu-restaurant'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.RestaurantMenu')}
                  </div>
                </div>
              </Link>    
            </div> 
            <div className="app-navigation-menu-item">
              <Link to="/rooms" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'rooms'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.Rooms')}
                  </div>
                </div>
              </Link>
            </div>
            <div className="app-navigation-menu-item">
              <Link to="/stories" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'stories'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.Stories')}
                  </div>
                </div>
              </Link>
            </div>
            <div className="app-navigation-menu-item">
              <Link to="/faq" onClick={closeMenu}>
                <div className="app-navigation-menu-item__wrapper">
                  <div className="app-navigation-menu-item__icon">
                    <SvgItem type={'stories'} />
                  </div>
                  <div className="app-navigation-menu-item__label">
                    {i18next.t('Components.SideNav.Faq')}
                  </div>
                </div>
              </Link>
            </div>
            {/* {state.isAuthenticated && (
              <>
                <IonItem>
                  <IonIcon icon={mailOutline} />
                  <IonLabel>{userData.email}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={notificationsOutline} />
                  <IonLabel>Important Notification</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={lockClosedOutline} />
                  <IonLabel>Change Password</IonLabel>
                </IonItem>
                <IonItem button="true">
                  <IonIcon icon={logOutOutline} />
                  <IonLabel onClick={handleSignOut}>Signout</IonLabel>
                </IonItem>
            
                <IonItemSliding>
                  <IonItemOptions side="start">
                    <IonItemOption onClick={() => alert("Remove clicked")}>
                      Remove
                    </IonItemOption>
                    <IonItemOption
                      color="danger"
                      onClick={() => alert("Close clicked")}
                    >
                      Close
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItem>
                    <IonIcon icon={trashOutline} />
                    <IonLabel>Remove Account</IonLabel>
                  </IonItem>
                </IonItemSliding>
                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header="Passwort Reset"
                  message={message}
                  buttons={["OK"]}
                />
              </>
            )} */}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default SideBarMenu;
