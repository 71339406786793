import { menuController } from "@ionic/core/components";
import { IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getWeatherDetails } from '../../services/api';
import { HTTPS } from '../../services/api/Api.Constant';
import HeaderComponent from '../HeaderComponent';
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";
import SvgItem from "../SvgItem";
import i18next from "i18next";
import { Link } from "react-router-dom";
const LoginRequireComponent = ({ pathname }) => {
  const history = useHistory();
  const handleSignIn = () => {
    menuController.close();
    history.push("/login");
  };
  const handleRegistration = () => {
    menuController.close();
    history.push("/registration");
  };
  console.log("pathname", pathname)
  return (
    <IonPage>
      {/* <HeaderComponent></HeaderComponent> */}
      <IonContent>
        <div className="protected-page">
          <div className="wrap">
            <div className="protected-page__inner">
              <div className="protected-page-header">
                <div className="app-back-button">
                  <Link to={"/home"}>
                    <SvgItem type={"back"} />
                  </Link>
                </div>
                <div className="protected-page-logo">
                  <SvgItem type={'logo'} />
                </div>
              </div>
              <div className="protected-page-headline">
                {pathname == "/live-webcam" && <><h1>{i18next.t('Pages.RequireLogin.LiveWebcam.Header.Title')}</h1></>}
                {pathname == "/tablereservation" && <><h1>{i18next.t('Pages.RequireLogin.TableReservation.Header.Title')}</h1></>}
              </div>
              <div className="protected-page__animated-icon">
                {Platform.OS == "web" && (
                  <>
                    <LottieWeb
                      source={require('../../assets/lottie/login/sign-up.json')}
                      autoPlay
                      loop
                    />
                  </>
                )}
                {(Platform.OS == "android" ||
                  Platform.OS == "ios") && (
                    <>
                      <Lottie
                        source={require('../../assets/lottie/login/sign-up.json')}
                        autoPlay
                        loop
                      />
                    </>
                  )}
              </div>
              <div className="protected-page__content">
                {i18next.t('Pages.RequireLogin.Common.Content')}
                <div className="protected-page__force-login">
                  <IonRow>
                    <IonCol>
                      <button className="btn btn-colored"
                        onClick={handleSignIn}>{i18next.t('Pages.RequireLogin.Common.Login')}</button>
                    </IonCol>
                    <IonCol>
                      <button className="btn btn-outline"
                        onClick={handleRegistration}>{i18next.t('Pages.RequireLogin.Common.Register')}</button>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginRequireComponent;

