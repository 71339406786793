import {
  IonCol,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRow,
  IonFooter,
  IonToolbar,
  IonSkeletonText
} from "@ionic/react";
import { useEffect, useState } from "react";
import HeaderBackComponent from "../HeaderBackComponent";
import SvgItem from "../SvgItem";
import { getCurrentMenuData } from "../../services/api";
import { Link } from "react-router-dom";
import i18next from "i18next";

const RestaurantMenuComponent = () => {
  const [menuData, setMenuData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(async () => {
    setIsLoading(true)
    let data = await getCurrentMenuData();
    if (data.status == 200) {
      setMenuData(data.data.data);
      setIsLoading(false)
    }
  }, []);
  return (
    <IonPage>
      <HeaderBackComponent title={i18next.t('Pages.RestaurantMenu.Header.Title')} backButton="/home" />
      <IonContent fullscreen>
        <div className="app-page-wrapper">
          <div className="app-page-content">

            <div className="restaurant-menu">
            {isLoading && <>
              <div className="restaurant-menu-category">
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="restaurant-menu-category-item-top">
                  <div className="restaurant-menu-category-item">
                    <div className="restaurant-menu-category-item-wrapper">
                      <div className="restaurant-menu-category-item-image">
                        <IonSkeletonText animated style={{ width: '100%', borderRadius: '12px', height: '170px' }} />
                      </div>
                      <div className="restaurant-menu-category-item-title">
                        <h3>
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              </>}

              <div className="restaurant-menu-category">
                {console.log(menuData)}
                {menuData &&
                  menuData.food_category &&
                  Object.keys(menuData.food_category).length > 0 &&
                  Object.keys(menuData.food_category).map((category) => {
                    return (
                      <Link
                        to={
                          "/restaurant-menu/category/?menu_id=" +
                          menuData.id +
                          "&category_id=" +
                          category
                        }
                        className="restaurant-menu-category-item-top"
                      >
                        <div className="restaurant-menu-category-item">
                          <div className="restaurant-menu-category-item-wrapper">
                            {/* <div className="restaurant-menu-category-item-overlay"></div> */}
                            <div className="restaurant-menu-category-item-image">
                              <img src={menuData.food_category[category].image} />
                            </div>
                            <div className="restaurant-menu-category-item-title">
                              <h3>
                                {menuData.food_category[category].category_name}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Link>
                      // <div className="resturant-menu-block">
                      //   <div className="title">
                      //     <div className="icon">
                      //       <SvgItem type={"menu-starter"} />
                      //     </div>
                      //     <div className="label">
                      //       {menuData.food_category[category].category_name}
                      //     </div>
                      //   </div>
                      //   <div className="restaurant-menu-list">
                      //     {menuData.food_category[category].products &&
                      //       menuData.food_category[category].products.map(
                      //         (product) => {
                      //           return (
                      //             <div className="restaurant-menu-list-item">
                      //               <div className="product-details">
                      //                 <div className="name">{product.title}</div>
                      //                 <div className="description">
                      //                   {product.description}
                      //                 </div>
                      //               </div>
                      //               <div className="product-allergens">
                      //                 <div className="label">Allergeni</div>
                      //                 <div className="icons">
                      //                   {product.allergens &&
                      //                     product.allergens.map((term) => {
                      //                       return (
                      //                         <div className="icon">
                      //                           <img src={term.image} />
                      //                         </div>
                      //                       );
                      //                     })}
                      //                 </div>
                      //               </div>
                      //             </div>
                      //           );
                      //         }
                      //       )}
                      //   </div>
                      // </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      {/* <IonFooter className="app-footer app-footer-menu-restaurant">
        <IonToolbar>
          <div className="title">
            <h3>Filtra il menu</h3>
          </div>
          <div className="app-restaurant-anchors">
            <button
              className="btn btn-icon"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              <div className="icon">
                <SvgItem type={"menu-starter"} />
              </div>
              <div className="label">Antipasti</div>
            </button>
            <button
              className="btn btn-icon"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              <div className="icon">
                <SvgItem type={"menu-starter"} />
              </div>
              <div className="label">Antipasti</div>
            </button>
            <button
              className="btn btn-icon"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              <div className="icon">
                <SvgItem type={"menu-starter"} />
              </div>
              <div className="label">Antipasti</div>
            </button>
            <button
              className="btn btn-icon"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              <div className="icon">
                <SvgItem type={"menu-starter"} />
              </div>
              <div className="label">Antipasti</div>
            </button>
            <button
              className="btn btn-icon"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              <div className="icon">
                <SvgItem type={"menu-starter"} />
              </div>
              <div className="label">Antipasti</div>
            </button>
          </div>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );
};

export default RestaurantMenuComponent;
