import { IonImg, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import { AppContext } from '../../store/State';
import i18next from 'i18next';
//import './index.css';

const HeaderCard = () => {

  const { state } = useContext(AppContext);

  return (
    <div className="main-header-card">
      <div className="main-header-card-image">
        <img src={"assets/images/Chierego-Drone-4-2.jpg"}/>
      </div>
      {/* <div className="main-header-card-overlay"></div> */}
      <div className="main-header-card-title">
        <h3>{i18next.t('Pages.Home.Header.Title')}</h3>
        <h1>Rifugio Chierego</h1>
      </div>
    </div>
  );
};

export default HeaderCard;

