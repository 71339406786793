import React from 'react';
import BlogComponent from '../components/BlogComponent';

const Blogs = () => {

  return (
    <BlogComponent></BlogComponent>
  );
};

export default Blogs;
