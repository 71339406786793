import React from 'react';
import TableReservationComponent from '../components/TableReservationComponent';

const TableReservation = () => {

  return (
    <TableReservationComponent></TableReservationComponent>
  );
};

export default TableReservation;