import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonItem, IonPage, IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonSkeletonText,
  IonCol,
  IonRow
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { getFaqList } from '../../services/api';
import HeaderBackComponent from '../HeaderBackComponent';
import { caretDownCircle } from 'ionicons/icons';

const FaqComponent = () => {

  const [faq, setFaqData] = useState([]);
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getFaqList()
      .then((response) => { setLoading(false); setFaqData(response.data.faq) })
      .catch((error) => { setLoading(false); alert(error) })
      .finally();
  }, []);



  return (
    <IonPage>
      <HeaderBackComponent
        title="Faq"
        backButton="/home"
      />
      <IonContent fullscreen>
        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="app-faq-page">
              <div className="app-faq-title">
                <h4>Guida, consigli e risposte per una visita al Rifugio Chierego</h4>
              </div>
              <div className="app-faq">
                {isLoading && <>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div className="app-faq-skeleton">
                    <IonRow>
                      <IonCol size="2">
                        <IonSkeletonText animated style={{ height: '20px', width: '20px', borderRadius: '6px'}} />
                      </IonCol>
                      <IonCol size="10">
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                        <div>
                          <IonSkeletonText animated style={{ height: '20px', width: '100%', borderRadius: '6px'}} />
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  
                </>}
                <IonAccordionGroup expand="inset">
                  {faq.map((faqData, index) => {
                    return (

                      <IonAccordion className="app-faq-item" toggle-icon-slot="start" value={index}>
                        <IonItem className="app-faq-item-header" slot="header" color="light">
                          <div className="app-faq-item-header-content">
                            {faqData.question}
                          </div>
                        </IonItem>
                        <div className="app-faq-item-content" slot="content">
                          {faqData.answer}
                        </div>
                      </IonAccordion>
                    )
                  })}

                </IonAccordionGroup>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FaqComponent;
