import { IonContent, IonPage, IonFooter, IonToolbar, IonSkeletonText} from "@ionic/react";
import { useEffect, useState } from "react";
import HeaderBackComponent from "../HeaderBackComponent";
import SvgItem from "../SvgItem";
import { getCurrentMenuCategoryData } from "../../services/api";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
const ResturantMenuCategoryComponent = () => {
  const [menuData, setMenuData] = useState({});
  const search = useLocation().search;
  const menu_id = new URLSearchParams(search).get("menu_id");
  const category_id = new URLSearchParams(search).get("category_id");
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(async () => {
    setIsLoading(true)
    console.log(menu_id, category_id);
    let data = await getCurrentMenuCategoryData(menu_id, category_id);
    if (data.status == 200) {
      setMenuData(data.data.data);
      setIsLoading(false)
    }
  }, [menu_id, category_id]);

  return (
    <IonPage>
      <HeaderBackComponent title={menuData.category_name} subtitle="Il menu del ristorante" backButton="/restaurant-menu" />
      {menuData && (
        <IonContent fullscreen>
          <div className="app-page-wrapper">
            <div className="app-page-content">
              <div className="restaurant-menu">
                  <div className="resturant-menu-block">
                    <div className="restaurant-menu-list">
                    {isLoading && <>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                      <div className="restaurant-menu-list-item">
                        <div className="product-details">
                          <div className="name"><h5><IonSkeletonText animated style={{ height: '20px', width: '80%', borderRadius: '6px'}} /></h5></div>
                        </div>
                        <div className="product-price">
                          <span><IonSkeletonText animated style={{ width: '20px', borderRadius: '6px'}} /></span>
                        </div>
                        <div className="product-allergens">
                          <IonSkeletonText animated style={{ width: '80%', borderRadius: '6px'}} />
                        </div>
                      </div>
                    </>
                    }
                      {menuData.category_products &&
                        menuData.category_products.map((product) => {
                          return (
                            <div className="restaurant-menu-list-item">
                              <div className="product-details">
                                <div className="name"><h5>{product.title}</h5></div>
                                <div className="description">
                                  {product.description}
                                </div>
                              </div>
                              <div className="product-price">
                                <span>{product.price} €</span>
                              </div>
                              <div className="product-allergens">
                                <div className="label">{i18next.t('Pages.RestaurantMenu.Content.Allergens')}</div>
                                <div className="icons">
                                  {product.allergens &&
                                    product.allergens.map((term) => {
                                      return (
                                        <div className="icon">
                                          <img src={term.image} />
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </IonContent>
      )}
      <IonFooter className="app-footer app-footer-menu-restaurant">
        <IonToolbar>
          <div className="title">
            <h3>{i18next.t('Pages.RestaurantMenu.Filter.Title')}</h3>
          </div>
          <div className="app-restaurant-anchors">
            <button
              className="btn btn-colored"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            > 
              {i18next.t('Pages.RestaurantMenu.Filter.Adult')}
            </button>
            <button
              className="btn btn-outline"
              //disabled={isLoading}
              shape="round"
              //onClick={handleTableBook}
            >
              {i18next.t('Pages.RestaurantMenu.Filter.Children')}
            </button>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ResturantMenuCategoryComponent;
