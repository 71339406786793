
import { IonContent, IonHeader, IonIcon, IonMenu, IonMenuButton, IonToolbar } from '@ionic/react';
import '@ionic/react/css/core.css';
import { closeCircle, closeOutline } from 'ionicons/icons';
import * as React from 'react';
import SideBarMenu from '../SideBarMenu';
import './index.css';

const SideMenuComponent = () => {

  return (
    <IonMenu side="end" menuId="sidebar" contentId="main-content">
      <IonHeader className="app-header-menu ion-no-border" translucent={false} >
        <IonToolbar>
          <IonMenuButton slot='end' color="dark" menu="sidebar">
            <IonIcon size='medium' icon={closeOutline}></IonIcon>
          </IonMenuButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SideBarMenu></SideBarMenu>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenuComponent;

