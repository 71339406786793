import React from 'react';
import FaqComponent from '../components/FaqComponent';

const Faq = () => {

  return (
    <FaqComponent></FaqComponent>
  );
};

export default Faq;
