import { IonContent, IonPage } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import HeaderComponent from '../components/HeaderComponent';
import HeaderBackComponent from '../components/HeaderBackComponent';
import TodayWeatherComponent from '../components/TodayWeatherComponent';
import i18next from 'i18next';

const Camera = () => {
  return (
    <IonPage>
      <HeaderBackComponent
        title={i18next.t('Pages.LiveWebcam.Header.Title')}
        backButton="/home"
      ></HeaderBackComponent>
      <IonContent fullscreen>
        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="livewebcam-iframe">
              <img src="https://www.vololiberoscaligero.org/chierego/image.php" />
            </div>
            <div className="webcamp-weather">
              <TodayWeatherComponent />
            </div>
            {/* <div className="app-block">
              <div className="app-block-title">
                <p>Il meteo in dettaglio</p>
              </div>
              <div className="app-block-content">
                <div className="app-block-content__inner">
                  <p>A Monte Baldo oggi cieli in prevalenza poco o parzialmente nuvolosi per l’intera giornata, non sono previste piogge nelle prossime ore. Durante la giornata di oggi la temperatura massima registrata sarà di 6°C, la minima di -2°C, lo zero termico si attesterà a 3339m. I venti saranno al mattino deboli e proverranno da Sudovest, al pomeriggio assenti e proverranno da Nordovest. Allerte meteo previste: ghiaccio.</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Camera;
