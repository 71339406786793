const ROLE_BASED_CAPABILITY = {
  administrator: ["navigation-webcam", "camera", "confirm_reservation"],
  subscriber: ["navigation-webcam", "camera"],
};

export const checkHasCapability = (userData, cap) => {
  let hasCapability = false;
  if (userData && userData.roles) {
    const roles = userData.roles;
    roles &&
      roles.forEach((role) => {
        if (
          ROLE_BASED_CAPABILITY[role] &&
          ROLE_BASED_CAPABILITY[role].includes(cap)
        ) {
          hasCapability = true;
        }
      });
  }
  return hasCapability;
};
