export const getNotificationUrl = (data_obj) => {
  //for reservation create
  if (data_obj.action == "new_table_booking") {
    return "/tablereservation-list/" + data_obj.reservation_id;
  }

  //for reservation cancelled
  if (data_obj.action == "booking_cancelled") {
    return "/tablereservation-list/" + data_obj.reservation_id;
  }

  //for reservation accepted
  if (data_obj.action == "booking_accepted") {
    return "/tablereservation-list/" + data_obj.reservation_id;
  }

  //for reservation Comment Add
  if (data_obj.action == "add_new_comment") {
    return "/tablereservation-list/" + data_obj.reservation_id;
  }
};
