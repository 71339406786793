const Reducer = (state, action) => {
  //console.log("state", state, action);
  switch (action.type) {
    case "setIsAuthenticated": {
      return { ...state, isAuthenticated: action.isAuthenticated };
    }
    case "setUserDetails": {
      return { ...state, userData: action.userData };
    }
    case "setFcmToken": {
      return { ...state, fcmToken: action.fcmToken };
    }
    case "getNotifications": {
      return { ...state, notifications: action.notifications };
    }
  }
  return state;
};

export default Reducer;
