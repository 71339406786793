import { IonContent, IonPage } from '@ionic/react';
import ExploreContainer from '../ExploreContainer';
import HeaderComponent from '../HeaderComponent';
import './index.scss';

const NotificationComponent = () => {

  return (
    <IonPage>
      <HeaderComponent></HeaderComponent>
      <IonContent fullscreen>
        <ExploreContainer name="Notification page" />
      </IonContent>
    </IonPage>
  );
};

export default NotificationComponent;