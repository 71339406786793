import {
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../store/State";
import SvgItem from "../SvgItem";

const HeaderBackComponent = ({ title, backButton, type, withLogo, subtitle, backButtonType }) => {
  const { state } = useContext(AppContext);
  // StatusBar.setStyle({ style: Style.Light });
  return (
    <IonHeader translucent={false} className={'app-header app-header-type-' + type + ' app-header app-header-back ' + (withLogo ? 'app-header-with-logo' : '') + ' ion-no-border'}>
      <div className="app-header-wrapper">
        <IonToolbar className="no-bg">
          {backButton && (
            <div className={'back-button ' + type}>
              <Link to={backButton}><SvgItem type={"back"} /></Link>
            </div>
          )}
          {withLogo && (
            <div className="app-header-logo">
              <SvgItem type={'logo'} />
            </div>
          )}
          {withLogo === undefined && (
            <div className="app-header-title">{title ? title : ''}</div>
          )}
          {withLogo !== undefined && (<IonTitle>{title ? title : ''}</IonTitle>)}

        </IonToolbar>
      </div>
    </IonHeader>
  );
};

export default HeaderBackComponent;
