import React from 'react';
import RoomComponent from '../components/RoomComponent';

const Room = () => {

  return (
    <RoomComponent></RoomComponent>
  );
};

export default Room;