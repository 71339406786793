import WeatherComponent from '../components/WeatherComponent';

const Weather = () => {

  return (
    <WeatherComponent></WeatherComponent>
  );
};

export default Weather;
