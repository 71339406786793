import React from 'react';
import NotificationComponent from '../components/NotificationComponent';

const Notification = () => {

  return (
    <NotificationComponent></NotificationComponent>
  );
};

export default Notification;