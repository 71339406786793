import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRouterLink,
  IonRow
} from "@ionic/react";
import { personOutline, tvOutline, wifi } from "ionicons/icons";
import { useEffect, useState } from "react";
import { getRoomList } from "../../services/api";
import HeaderBackComponent from "../HeaderBackComponent";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";
import i18next from "i18next";

const RoomComponent = () => {
  const [rooms, setRooms] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getRoomList()
        .then(result => {
          if (result.statusCode === 200) {
            setRooms(result.data.rooms);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          setShowLoading(false);
        });
    })();
  }, []);
  return (
    <IonPage>
      <HeaderBackComponent
        title={i18next.t('Pages.Room.List.Header.Title')}
        backButton="/home"
      />
      <IonContent fullscreen>
        {showLoading && <>
          {Platform.OS == "web" && (
            <>
              <div className="page-content-loader">
                <div className="page-content-loader-icon">
                  <LottieWeb
                    source={require("../../assets/lottie/loader/loader.json")}
                    autoPlay
                    loop
                    height={120}
                    width={120}
                  />
                </div>
                <div className="page-content-loader-text">
                  <h3>Stiamo caricando le camerate...</h3>
                </div>
              </div>
            </>
          )}
          {(Platform.OS == "android" ||
            Platform.OS == "ios") && (
              <>
                <div className="page-content-loader">
                  <div className="page-content-loader-icon">
                    <LottieWeb
                      source={require("../../assets/lottie/loader/loader.json")}
                      autoPlay
                      loop
                      height={120}
                      width={120}
                    />
                  </div>
                  <div className="page-content-loader-text">
                    <h3>Stiamo caricando le camerate...</h3>
                  </div>
                </div>
              </>
            )}
        </>}

        <div className="app-page-wrapper">
          <div className="app-page-content">
            <div className="rooms-list">
              {rooms &&
                rooms.map((room, index) => {
                  return (
                    // <div className="room-card">
                    //    <IonRouterLink href={"room/" + room.ID}>
                    //     <div className="room-card-wrapper"></div>
                    //     <IonRow>
                    //         <IonCol size="4">
                    //           <IonImg src={room.gallery_room[0]}></IonImg>
                    //         </IonCol>
                    //    </IonRouterLink>
                    // </div>
                    <div className="room-card">
                      <IonRouterLink href={"rooms/" + room.ID}>
                        <div className="room-card-wrapper">
                          <IonRow>
                            <IonCol size="5">
                              <div className="room-card-image">
                                <img src={room.featured_image} />
                              </div>
                            </IonCol>
                            <IonCol size="7">
                              <div className="room-card-details">
                                <div className="room-card-details-header">
                                  <div className="room-card-name">
                                    <h3>{room.title}</h3>
                                  </div>
                                  <div className="room-card-info">
                                    <div className="room-card-details-item">
                                      <span>{room.details_room.person} {i18next.t('Pages.Room.Common.Person')}</span>
                                    </div>
                                    <div className="divider">/</div>
                                    <div className="room-card-details-item">
                                      <span>{room.details_room.mq} {i18next.t('Pages.Room.Common.MQ')}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="room-card-price">
                                <div className="price"><h4>{room.room_price_adult} € {i18next.t('Pages.Room.Common.Adult')}</h4></div>
                                  <div className="price"><h4>{room.room_price_children} € {i18next.t('Pages.Room.Common.Children')}</h4></div>
                                </div>
                              </div>
                            </IonCol>
                          </IonRow>
                        </div>
                      </IonRouterLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RoomComponent;
