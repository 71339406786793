export const TRANSLATIONS_IT = {
  Header: {
    Hello: "Hello IT"
  },
  Common: {
    Form: {
      Name: "Nome",
      Surname: "Cognome",
      Email: "Email",
      Phone: "Numero di telefono",
      Password: "Password",
      OTP: "Codice di sicurezza",
      Country: "Paese",
      Language: "Lingua",
      Login: "Accedi",
      Register: "Registrati",
      NewPasswordSubmit: "Procedi",
      PolicyControl: "* Autorizzo il trattamento dei miei dati personali identificativi nelle modalità e per le finalità indicate nell'informativa privacy",
      LostPassword: "Hai dimenticato la password?",
      SocialLogin: {
        Title: "Oppure",
        Facebook: {
          Submit: "Continua su Facebook",
        },
        Google: {
          Submit: "Continua su Google",
        }
      }
    }
  },
  Components: {
    BottomNav: {
      Home:"Home",
      TableReservation: "Prenota",
      Room: "Camere",
      Webcam: "Webcam",
      Menu: "Menu",
    },
    SideNav: {
      Title: "Menu di navigazione",
      MyAccount: "Il mio account",
      LiveWebcam: "Live Webcam",
      Weather: "Meteo",
      TableReservation: "Prenota il tuo tavolo",
      RestaurantMenu: "Il menu del rifugio",
      Rooms: "Le camere",
      Stories: "Storie",
      Faq: "Faq",
      Logout: "Logout",
      MyReservation: "Le mie prenotazioni",
      ReservationAdmin: "Controlla prenotazioni",
    },
    TodayWeather: {
      Title: "Il meteo di oggi"
    }
  },
  Pages: {
    Home: {
      Header: {
        Title: "Benvenuto al",
      },
      Services: {
        Title: "Trova il tuo servizio",
        TableBooking: "Prenota un tavolo",
        RoomBooking: "Prenota una camera"
      }
    },
    Registration: {
      Header: {
        Title: "Benvenuto!",
        Subtitle: "Crea un account per accedere a tutte le funzionalità",
      }
    },
    Login: {
      Header: {
        Title: "Bentornato!",
        Subtitle: "Usa le credenziali qui sotto ed accedi al tuo account",
      }
    },
    RequireLogin: {
      Common: {
        Content: "Per favore effettua la registrazione o accedi con il tuo account.",
        Login: "Accedi",
        Register: "Registrati",
      },
      LiveWebcam: {
        Header: {
          Title: "La visione della webcam è riservata agli utenti registrati.",
        }
      },
      TableReservation: {
        Header: {
          Title: "La prenotazione del tavolo è riservata agli utenti registrati.",
        }
      },
    },
    NewPassword: {
      Header: {
        Title: "Nuova Password",
        Subtitle: "Controlla la tua casella di posta, inserisci il codice presente nella mail ed imposta una nuova password.",
      },
      Content: {

      }
    },
    Room: {
      List: {
        Header: {
          Title: "Le camerate",
        },
      },
      Details: {

      },
      Common: {
        Person: "Posti Letto",
        MQ: "mq",
        Bathroom: "Bagno in comune",
        Adult: "per adulto",
        Children: "per bambino",
        Children_example: "Sotto i 12 anni",
      }
    },
    TableReservation: {
      Header: {
        Title: "Prenota un tavolo",
      },
      Form: {
        Person: {
          StepTitle: "In quanti sarete?",
          Adult: "Adulti",
          Children: "Bambini"
        },
        DateSlot: {
          StepTitle: "Quando vorresti venire?",
          Month: "Mese",
        },
        TimeSlot: {
          StepTitle: "Scegli un orario",
          Dinner: "Gli orari per la cena",
          Lunch: "Gli orari per il pranzo",
        },
        OtherInformation: {
          StepTitle: "Altre Informazioni",
          Note: "Note aggiuntive",
          NotePlaceholder: "Esempio: “Mi serve un seggiolone”, “Sono intollerante al lattosio”.",
        },
        Submit: "Prenota ora",
      }
    },
    TableReservationList: {
      Header: {
        Admin: {
          Title: "Lista prenotazioni",
        },
        User: {
          Title: "Le tue prenotazioni",
        }
      },
      Content: {
        Status: {
          FilterLabel: "Filtra per stato",
          All: "Tutti",
          Rejected: "Rifiutato",
          Accepted: "Accettato",
          Requested: "In attesa",
        },
        AdultPlural: "Adulti",
        AdultSingular: "Adulto",
        ChildrenPlural: "Bambini",
        ChildrenSingular: "Bambino"
      }
    },
    TableReservationAction:{
      Accept: {
        Title: "Conferma la prenotazione",
        Step1: {
          Title: "Seleziona una data",
          Subtitle: "Conferma o imposta una nuova data concordata con il cliente.",
        },
        Step2: {
          Title: "Seleziona una slot orario",
          Subtitle: "Conferma o imposta un nuovo orario concordato con il cliente.",
        },
        Step3: {
          Title: "Altre informazioni",
          Subtitle: "Aggiungi un commento se devi comunicare qualcosa al cliente.",
        },
        AcceptButton: "Conferma",
        CancelButton: "Cancella",
      },
      Decline: {
        Title: "Rifiuta la prenotazione",
        Step1: {
          Title: "Sei sicuro di voler procedere?",
          Subtitle: "Aggiungi un commento se devi comunicare qualcosa al cliente.",
        },
        AcceptButton: "Conferma",
        RejectButton: "Rifiuta",
        CancelButton: "Cancella",
      }
    },
    TableReservationDetail: {
      Header: {
        Title: "Prenotazione n°",
      },
      ReservationDetails: {
        Title: "Dati della prenotazione",
      },
      ReservationDetailsClient: {
        Title: "Dati del cliente",
      },
      Comments: {
        Title: "Conversazione",
        PlaceholderTextarea: "Aggiungi un commento qui...",
      }
    },
    RestaurantMenu: {
      Header: {
        Title: "Il menu del rifugio",
      },
      Content: {
        Allergens: "Allergeni",
      },
      Filter: {
        Title: "Filtra il menu",
        Adult: "Adulti",
        Children: "Bambini"
      }
    },
    LiveWebcam: {
      Header: {
        Title: "Live Webcam",
      }
    }
  }
};