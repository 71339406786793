import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow, IonFooter, IonToolbar, IonButton
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getWeatherDetails } from "../../services/api";
import { HTTPS } from "../../services/api/Api.Constant";
import HeaderBackComponent from "../HeaderBackComponent";
import HeaderCard from "../HeaderCard";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";
import moment from "moment";
import "moment/locale/it";


const WeatherComponent = () => {
  const currentHour = new Date().getHours();
  const [currentDay, setCurrentDay] = useState(0)
  const slideOpts = {
    spaceBetween: 50,
    slidesPerView: 3,
    loop: true,
    initialSlide: currentHour,
  };
  const [forecastWeatherAll, setForecastDayWeatherAll] = useState([]);
  const [forecastDayWeather, setForecastDayWeather] = useState([]);
  const [currentWeather, setCurrentWeather] = useState();
  const weatherSlides = useRef(document.createElement("ion-slides"));


  useEffect(() => {
    console.log("wwww")
    getWeatherDetails(10)
      .then((response) => {
        setForecastDayWeatherAll(response.data.forecast.forecastday);
      })
      .catch((error) => { console.log(error) })
      .finally();
  }, []);

  useEffect(() => {
    if (forecastWeatherAll.length > 0) {
      setForecastDayWeather(forecastWeatherAll[currentDay]);
    }
  }, [forecastWeatherAll, currentDay]);

  useEffect(() => {
    if (forecastDayWeather && forecastDayWeather.hour) {
      setCurrentWeather(forecastDayWeather.hour[currentHour - 1])
    }
  }, [forecastDayWeather]);

  const handleSlideChange = (event) => {
    setCurrentWeather(event)
  };

  const getLottieJSON = (name) => {
    let data = {};

    // if (name == "Patchy rain possible" || name == "Patchy sleet possible" || name == "Patchy light rain" || name == "Light rain" || name == "Moderate rain at times" || name == "Moderate rain" || name == "Heavy rain at times" || name == "Light freezing rain" || name == "Moderate or heavy freezing rain") {
    //   data = require("../../assets/lottie/weather/cloud-rain.json")
    // } else if (name == "Cloudy" || name == "Mist") {
    //   data = require("../../assets/lottie/weather/cloud.json")
    // } else if (name == "Partly cloudy") {
    //   data = require("../../assets/lottie/weather/partly-cloudy.json")
    // } else if (name == "Clear") {
    //   data = require("../../assets/lottie/weather/moon.json")
    // } else if (name == "Fog" || name == "Overcast" || name == "Fog" || name == "Freezing fog") {
    //   data = require("../../assets/lottie/weather/wind.json")
    // } else if (name == "Sunny") {
    //   data = require("../../assets/lottie/weather/sun.json")
    // } else if (name == "Patchy snow possible" || name == "Patchy freezing drizzle possible" || name == "Blowing snow" || name == "Patchy light drizzle" || name == "Freezing drizzle" || name == "Heavy freezing drizzle") {
    //   data = require("../../assets/lottie/weather/snowflake.json")
    // } else if (name == "Thundery outbreaks possible" || name == "Heavy rain") {
    //   return require("../../assets/lottie/weather/cloud-thunderstorm.json")
    // } else {
    //   // console.log("name", name)
    //   data = require("../../assets/lottie/weather/cloud-thunderstorm.json")
    // }
    return data;
  }
  console.log(currentWeather)
  return (
    <IonPage>

      <HeaderBackComponent backButton="/"></HeaderBackComponent>

      <IonContent class={currentWeather ? "weather-background weather-condition-" + currentWeather.condition.code : "weather-background"} fullscreen={true}>
        {currentWeather && <>
          <IonLabel>{currentWeather.condition.text}</IonLabel>
          {currentWeather.temp_c} &#8451;</>}
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Swiper
            spaceBetween={5}
            initialSlide={currentHour + 3}
            slidesPerView={3.3}
            onSlideChange={() => { }}
            onSwiper={(swiper) => { }}
            loop={false}
          >
            {forecastDayWeather && forecastDayWeather.hour && forecastDayWeather.hour.length > 0 && forecastDayWeather.hour.map((hourWeather, index) => {
              // if (currentHour === index) {
              //   setCurrentWeather(hourWeather)
              // }
              return (
                <SwiperSlide
                  ref={weatherSlides} key={index}
                  onClick={() => handleSlideChange(hourWeather)}
                >
                  <IonCard
                    className={
                      (currentHour === index)
                        ? "card-weather active"
                        : "card-weather"
                    }
                  >
                    <IonCardContent>
                      <IonRow className="card-weather-content">
                        <IonCol size="12">
                          <IonLabel className="card-weather-content-label">
                            {index} : 00
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <div className="card-weather-content-icon">

                            {Platform.OS == "web" && (
                              <>
                                <LottieWeb
                                  source={getLottieJSON(hourWeather.condition.text)}
                                  autoPlay
                                  loop
                                />
                              </>
                            )}
                            {(Platform.OS == "android" ||
                              Platform.OS == "ios") && (
                                <>
                                  <Lottie
                                    source={getLottieJSON(hourWeather.condition.text)}
                                    autoPlay
                                    loop
                                  />
                                </>
                              )}
                          </div>
                        </IonCol>
                        <IonCol size="12">
                          <IonLabel className="card-weather-content-label">
                            {hourWeather.temp_c} &#8451;
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </SwiperSlide>
              );

            })}
          </Swiper>
          <IonRow>
            <IonCol>
              <IonButton onClick={() => { setCurrentDay(0) }} color={currentDay == 0 ? "success" : ""} fill="clear">
                Today
              </IonButton>
              <IonButton onClick={() => { setCurrentDay(1) }} color={currentDay == 1 ? "success" : ""} fill="clear">
                Tomorrow
              </IonButton>
              {forecastWeatherAll && forecastWeatherAll[2] && <IonButton onClick={() => { setCurrentDay(2) }} color={currentDay == 2 ? "success" : ""} fill="clear">
                {moment(forecastWeatherAll[2].date).format("DD MMMM")}
              </IonButton>}
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
};

export default WeatherComponent;
