import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getWeatherDetails } from "../../services/api";
import { HTTPS } from "../../services/api/Api.Constant";
import HeaderComponent from "../HeaderComponent";
import HeaderCard from "../HeaderCard";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import LottieWeb from "react-native-web-lottie";
import TodayWeatherComponent from "../TodayWeatherComponent";
import "./index.scss";
import ServiceCard from "../ServiceCard";

const HomeComponent = () => {




  return (
    <IonPage>
      <HeaderComponent></HeaderComponent>
      <IonContent>
        <HeaderCard />
        <TodayWeatherComponent />
        <ServiceCard />
      </IonContent>
    </IonPage>
  );
};

export default HomeComponent;
